import { Grid, Table, Radio, Flex, createStyles } from "@mantine/core";
import { LabelText } from "./LabelText";
import _ from "lodash";
import { ErrorText } from "./ErrorText";

type Props = {
  form: any;
  label: string;
  options: string[];
  questions: string[];
  questionIds: string[];
};

const useStyles = createStyles((theme) => ({
  customTbody: {
    display: "block",
    height: "400px",
    overflow: "auto",
    // "&::-webkit-scrollbar": {
    //   display: "none",
    // },
  },
  customThead: {
    display: "table",
    width: "100%",
    tableLayout: "fixed",
  },
  customTr: {
    display: "table",
    width: "100%",
    tableLayout: "fixed",
  },
  tableCell: {
    hyphens: "auto",
    WebkitHyphens: "auto",
  },
}));

export const MultiRadioTable = ({
  form,
  label,
  options,
  questions,
  questionIds,
}: Props) => {
  const { classes } = useStyles();

  return (
    <Grid>
      <Grid.Col span={12}>
        <LabelText>{label}</LabelText>
      </Grid.Col>
      <Grid.Col span={12}>
        <Table>
          <thead className={classes.customThead}>
            <tr>
              <th />
              {options.map((option) => (
                <th className={classes.tableCell} key={option}>
                  {option}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className={classes.customTbody}>
            {questions.map((question, index) => (
              <tr key={question} className={classes.customTr}>
                <td>
                  <Flex direction="column">
                    {question}
                    {form.errors[questionIds[index]] && (
                      <ErrorText>{form.errors[questionIds[index]]}</ErrorText>
                    )}
                  </Flex>
                </td>
                {options.map((option) => (
                  <td key={option}>
                    <Radio
                      checked={
                        _.get(form.values, questionIds[index]) === option
                      }
                      onChange={() => {
                        form.setFieldValue(questionIds[index], option);
                      }}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </Grid.Col>
    </Grid>
  );
};
