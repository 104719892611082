import { MultiRadioTable } from "../../components/MultiRadioTable";

type Props = {
  form: any;
};

const Component = ({ form }: Props) => {
  return (
    <MultiRadioTable
      form={form}
      label="En los últimos 12 meses, ¿has sido testigo de alguna de estas situaciones en el trabajo?"
      options={["Sí, he sido testigo.", "No he sido testigo."]}
      questions={[
        "Comentarios o bromas de contenido sexual o sexista.",
        "Comentarios o bromas sobre la orientación sexual o identidad de género de una persona.",
        "Comentarios sobre la apariencia física o cuerpo de una persona que generan incomodidad, incluyendo también gestos, silbidos o sonidos.",
        "Invitaciones reiteradas a salir a una persona a pesar de no recibir respuesta o no recibir respuesta positiva.",
        "Contactos reiterados a través de mensajes electrónicos, chats, WhatsApp, Facebook, entre otros, con fines no laborales que generan incomodidad.",
        "Solicitud o envío sin consentimiento de fotos o videos de material sexual explícito o de connotación sexual.",
        "Acercamientos físicos excesivos o tocamientos (rozar, sobar, masajear, etc.) sin necesidad a una persona en alguna parte del cuerpo como hombros, espalda baja, rodilla u otras partes.",
        "Tocamientos o roces a una persona en zonas privadas del cuerpo como los senos, genitales, etc.",
        "Miradas persistentes con connotación sexual a una persona.",
        "Insinuaciones y/o invitaciones de un jefe/a a salir a pesar que de alguna manera se le mostró incomodidad.",
        "Insinuaciones o propuestas de mejoras en la situación laboral de una persona cambio de aceptar invitaciones, acercamientos o encuentros sexuales.",
        "Amenazar o insinuar con despedir a una persona, retirarle los beneficios laborales o tomar alguna represalia por no aceptar invitaciones, acercamientos, o encuentros sexuales.",
      ]}
      questionIds={[
        "TT_001",
        "TT_002",
        "TT_003",
        "TT_004",
        "TT_005",
        "TT_006",
        "TT_007",
        "TT_008",
        "TT_009",
        "TT_010",
        "TT_011",
        "TT_012",
      ]}
    />
  );
};

export default Component;
