import { Checkbox } from "@mantine/core";
import CheckboxSurvey from "../../components/form/Checkbox";

type Props = {
  form: any;
};

const Component = ({ form }: Props) => {
  return (
    <CheckboxSurvey
      label="¿Cuál o cuáles de estas situaciones describirían la situación de hostigamiento o acoso sexual que has experimientado?"
      {...form.getInputProps("AD_002_013")}
    >
      <Checkbox
        label="Me hicieron comentarios o bromas de contenido sexual o sexista."
        value="ad_002"
      />
      <Checkbox
        label="Me hicieron comentarios o bromas sobre de mi orientación sexual o identidad de género."
        value="ad_003"
      />
      <Checkbox
        label="Me hicieron comentarios sobre mi apariencia física o cuerpo que me generaron incomodidad, incluyendo también gestos, silbidos o sonidos."
        value="ad_004"
      />
      <Checkbox
        label="Me invitaron reiteradamente a salir a pesar de que no ofrecí respuesta o no di respuesta positiva."
        value="ad_005"
      />
      <Checkbox
        label="Me contactaron reiteradamente a través de mensajes electrónicos, chats, WhatsApp, Facebook, entre otros, con fines no laborales que me incomodaron."
        value="ad_006"
      />
      <Checkbox
        label="Me solicitaron o enviaron sin consentimiento fotos o videos de material sexual explícito o de connotación sexual."
        value="ad_007"
      />
      <Checkbox
        label="Se acercaron demasiado físicamente o me tocaron (rozar, sobar, masajear, etc.) sin necesidad en alguna parte del cuerpo como hombros, espalda baja, rodilla u otras partes."
        value="ad_008"
      />
      <Checkbox
        label="Me tocaron y/o rozaron en zonas privadas del cuerpo, como senos, genitales, etc."
        value="ad_009"
      />
      <Checkbox
        label="Me miraron persistentemente con connotación sexual."
        value="ad_010"
      />
      <Checkbox
        label="Mi jefe/a se me insinuó y/o realizó invitaciones a salir a pesar que de alguna manera le expresé mi incomodidad."
        value="ad_011"
      />
      <Checkbox
        label="Me insinuaron o propusieron mejoras en mi situación laboral a cambio de aceptar invitaciones, acercamientos o encuentros sexuales."
        value="ad_012"
      />
      <Checkbox
        label="Me amenazaron o insinuaron con despedirme, retirarme beneficios laborales o tomar alguna represalia por no aceptar invitaciones, acercamientos, o encuentros sexuales."
        value="ad_013"
      />
    </CheckboxSurvey>
  );
};

export default Component;
