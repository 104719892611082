import { Radio } from "@mantine/core";
import { RadioSurvey } from "../../components";

type Props = {
  form: any;
};

const Component = ({ form }: Props) => {
  return (
    <RadioSurvey
      label="¿Con qué frecuencia crees que suceden casos de hostigamiento o acoso sexual en tu organización?"
      {...form.getInputProps("PL_001_percepcionLideres")}
    >
      <Radio label="Con mucha frecuencia." value="Con mucha frecuencia." />
      <Radio label="Con algo de frecuencia." value="Con algo de frecuencia." />
      <Radio label="Con poca frecuencia." value="Con poca frecuencia." />
      <Radio
        label="Con muy baja frecuencia."
        value="Con muy baja frecuencia."
      />
    </RadioSurvey>
  );
};

export default Component;
