import { Radio } from "@mantine/core";
import { RadioSurvey } from "../../components";

type Props = {
  form: any;
};

const Component = ({ form }: Props) => {
  return (
    <RadioSurvey
      label="¿Sabes en qué consiste el proceso de investigación en casos hostigamiento o acoso sexual laboral en tu empresa o institución?"
      {...form.getInputProps("SP_012_conoceProcesoDeInvestigacion")}
    >
      <Radio label="Sí, lo he revisado." value="Sí, lo he revisado." />
      <Radio label="No." value="No." />
    </RadioSurvey>
  );
};

export default Component;
