import { Radio } from "@mantine/core";
import { TStaffSurveyData } from "../../types";
import { RadioSurvey } from "../../components";

type Props = {
  form: any;
  staff_survey: TStaffSurveyData;
};

const Component = ({ form, staff_survey }: Props) => {
  return (
    <RadioSurvey
      label="Seleccione su género"
      {...form.getInputProps("IP_001_genero")}
    >
      <Radio label="Masculino." value="Masculino." />
      <Radio label="Femenino." value="Femenino." />
      {staff_survey.staff_survey.flag_001 && (
        <Radio
          label="No binario (no se identifica con las categorías de femenino o masculino)"
          value="No binario."
        />
      )}
    </RadioSurvey>
  );
};

export default Component;
