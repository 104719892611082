import { Space, Text } from "@mantine/core";

const Component = () => {
  return (
    <>
      <Text ta="justify">¡Vas muy bien!</Text>
      <Space h="md" />
      <Text ta="justify">
        En la siguiente parte de la encuesta te vamos a preguntar qué piensas
        sobre algunas situaciones que pueden presentarse en el trabajo.
        Agradecemos mucho tu participación y te reiteramos que la encuesta es
        anónima y confidencial.
      </Text>
    </>
  );
};

export default Component;
