import { MultiRadioTable } from "../../components/MultiRadioTable";

type Props = {
  form: any;
};

const Component = ({ form }: Props) => {
  return (
    <MultiRadioTable
      form={form}
      label="En relación a tu trabajo actual, ¿en los últimos 12 meses has experimentado alguna de estas situaciones?"
      options={["Sí, me ha pasado.", "No me ha pasado."]}
      questions={[
        "Me hicieron comentarios o bromas de contenido sexual o sexista.",
        "Me hicieron comentarios o bromas sobre de mi orientación sexual o identidad de género.",
        "Me hicieron comentarios sobre mi apariencia física o cuerpo que me generaron incomodidad, incluyendo también gestos, silbidos o sonidos.",
        "Me invitaron reiteradamente a salir a pesar de que no ofrecí respuesta o no di respuesta positiva.",
        "Me contactaron reiteradamente a través de mensajes electrónicos, chats, WhatsApp, Facebook, entre otros, con fines no laborales que me incomodaron.",
        "Me solicitaron o enviaron sin consentimiento fotos o videos de material sexual explícito o de connotación sexual.",
        "Se acercaron demasiado físicamente o me tocaron (rozar, sobar, masajear, etc.) sin necesidad en alguna parte del cuerpo como hombros, espalda baja, rodilla u otras partes.",
        "Me tocaron y/o rozaron en zonas privadas del cuerpo, como senos, genitales, etc.",
        "Me miraron persistentemente con connotación sexual.",
        "Mi jefe/a se me insinuó y/o realizó invitaciones a salir a pesar que de alguna manera le expresé mi incomodidad.",
        "Me insinuaron o propusieron mejoras en mi situación laboral a cambio de aceptar invitaciones, acercamientos o encuentros sexuales.",
        "Me amenazaron o insinuaron con despedirme, retirarme beneficios laborales o tomar alguna represalia por no aceptar invitaciones, acercamientos, o encuentros sexuales.",
      ]}
      questionIds={[
        "AT_001_comentariosConContenidoSexual",
        "AT_002_comentariosSobreOrientacionSexual",
        "AT_003_comentariosSobreAparienciaFisica",
        "AT_004_invitarASalir",
        "AT_005_contactarPorRedesSociales",
        "AT_006_solicitarMaterialSexual",
        "AT_007_rozamientosIndebidos",
        "AT_008_tocamientosIndebidos",
        "AT_009_mirarConConnotacionSexual",
        "AT_010_invitarASalir",
        "AT_011_insinuarMejoresCondicionesACambioDeRelaciones",
        "AT_012_amenazarConSuspenderContrato",
      ]}
    />
  );
};

export default Component;
