import { Radio } from "@mantine/core";
import { TStaffSurveyCustomField } from "../../types";
import { RadioSurvey } from "../../components";

type Props = {
  form: any;
  turnos: TStaffSurveyCustomField[];
};

const Component = ({ form, turnos }: Props) => {
  return (
    <RadioSurvey
      label="¿En qué turno trabajas?"
      {...form.getInputProps("IL_002_turno")}
    >
      {turnos.map((turno) => (
        <Radio
          label={turno.description}
          value={turno.id.toString()}
          key={turno.id}
        />
      ))}
    </RadioSurvey>
  );
};

export default Component;
