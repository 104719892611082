import { Carousel, Embla } from "@mantine/carousel";
import {
  Container,
  createStyles,
  Flex,
  Group,
  Loader,
  Progress,
  Text,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

import {
  SurveyWelcome1,
  SurveyWelcome2,
  SurveyAcknowledgement,
  SurveyNavigationButtons,
  Genero,
  Edad,
  Comunidad,
  Etnia,
  Contrato,
  Turno,
  TiempoTrabajando,
  Area,
  ContactoConClientes,
  PuestoDeTrabajo,
  ParticipacionEnComite,
  Jefe,
  CentroDeTrabajo,
  SurveyInfoPolitics,
  ConocePoliticaDePrevencion,
  HaSidoCapacitado,
  CanalDeDenunciasExiste,
  CanalesDeDenuncias,
  ConoceProcesoDeInvestigacion,
  SurveyInfoSituations,
  PercepcionLideres,
  Tolerancia,
  Conceptos,
  SurveyInfoPersonalExperience,
  HaSidoObjetoDeAcoso,
  PreguntasAcosoDeclarado,
  PreguntasAcosoTecnico,
  Frecuencia,
  GeneroAcosador,
  PreguntasPerfilAcosador,
  AccionesAcosoDeclarado,
  BarrerasDeDenuncia,
  Costos,
  PreguntasTestigosDeclarados,
  HaSidoTestigoDeAcoso,
  PreguntasTestigosTecnicos,
  PreguntasAccionesTestigos,
  HaSidoObjetoDeAcosoAlgunaVezEnSuVida,
  SurveyInfoTratamientoDeCasos,
  PreguntasConfianzaEnLaOrganizacion,
  PreguntaSatisfaccion,
  SurveyInfoCasos,
  CasoTemplate,
} from ".";
import { SurveyService } from "../../services";
import { TStaffSurveyData } from "../../types";
import { useLogo } from "../../context/Logo/LogoContext";

type Props = {
  previsualizar: boolean;
};

const useStyles = createStyles((theme) => ({
  carouselSlide: {
    overflowY: "auto",
    maxHeight: "500px",
    overflowX: "hidden",

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      maxHeight: "400px",
    },
  },
}));

export const SurveyPage = (props: Props) => {
  const { id: mpCode } = useParams();

  const [scrollProgress, setScrollProgress] = useState(0);
  const [embla, setEmbla] = useState<Embla | null>(null);
  const [survey, setSurvey] = useState<TStaffSurveyData>(null!);
  const [error, setError] = useState<string | null>(null);
  const [isLeader, setIsLeader] = useState<boolean>(false);
  const [esParteDeRecursosHumanos, setParteDeRecursosHumanos] =
    useState<boolean>(false);
  const [haSidoObjetoDeAcoso, setHaSidoObjetoDeAcoso] =
    useState<boolean>(false);
  const [haSidoTestigoDeAcoso, setHaSidoTestigoDeAcoso] =
    useState<boolean>(false);
  const [totalSlides, setTotalSlides] = useState<number>(0);
  const [encuestaVencida, setEncuestaVencida] = useState(false);
  const [encuestaTodaviaNoHaSidoLanzada, setEncuestaTodaviaNoHaSidoLanzada] =
    useState(false);
  const [loading, setLoading] = useState(false);

  const { updateLogo } = useLogo();

  const navigate = useNavigate();

  const handleScroll = useCallback(() => {
    if (!embla) return;
    const progress = Math.max(0, Math.min(1, embla.scrollProgress()));
    setScrollProgress(progress * 100);
  }, [embla, setScrollProgress]);

  useEffect(() => {
    if (embla) {
      embla.on("scroll", handleScroll);
      handleScroll();
    }
  }, [handleScroll, embla]);

  const { classes } = useStyles();

  const validateField = (value: any) =>
    !value ? "Por favor, marca tu respuesta." : null;
  const validateCheckbox = (value: any) =>
    value.length === 0 ? "Por favor, marca al menos una opción." : null;

  const form = useForm({
    initialValues: {
      IP_001_genero: "",
      IP_002_edad: "",
      IP_003_comunidad: "",
      IP_004_etnia: "",
      IL_001_contrato: "",
      IL_002_turno: "",
      IL_003_tiempoTrabajando: "",
      IL_004_area: "",
      IL_005_contactoConClientes: "",
      IL_006_puestoDeTrabajo: "",
      IL_007_participacionEnComite: "",
      IL_008_jefe: "",
      IL_009_centroDeTrabajo: "",
      SP_001_conocePoliticaDePrevencion: "",
      SP_002_haSidoCapacitado: "",
      SP_003_canalDeDenunciasExiste: "",
      SP_004_011_canalesDeDenuncias: [],
      SP_012_conoceProcesoDeInvestigacion: "",
      PL_001_percepcionLideres: "",
      TOL_001_bromasSexistas: "",
      TOL_002_bromasSobreIdentidadSexual: "",
      TOL_003_bromasSobreAparienciaFisica: "",
      TOL_004_invitarASalir: "",
      TOL_005_contactarPorRedesSociales: "",
      TOL_006_solicitarMaterialSexual: "",
      TOL_007_rozamientosIndebidos: "",
      TOL_008_tocamientosIndebidos: "",
      TOL_009_mirarConConnotacionSexual: "",
      TOL_010_invitarASalir: "",
      TOL_011_insinuarMejoresCondicionesACambioDeRelaciones: "",
      TOL_012_ameazarConSuspenderContrato: "",
      CPT_001: "",
      CPT_002: "",
      CPT_003: "",
      CPT_004: "",
      AD_001_haSidoObjetoDeAcoso: "",
      AT_001_comentariosConContenidoSexual: "",
      AT_002_comentariosSobreOrientacionSexual: "",
      AT_003_comentariosSobreAparienciaFisica: "",
      AT_004_invitarASalir: "",
      AT_005_contactarPorRedesSociales: "",
      AT_006_solicitarMaterialSexual: "",
      AT_007_rozamientosIndebidos: "",
      AT_008_tocamientosIndebidos: "",
      AT_009_mirarConConnotacionSexual: "",
      AT_010_invitarASalir: "",
      AT_011_insinuarMejoresCondicionesACambioDeRelaciones: "",
      AT_012_amenazarConSuspenderContrato: "",
      AD_002_013: [],
      FRE_001_frecuencia: "",
      PAD_001_002: [],
      PAD_003_007: [],
      AAD_001: "",
      AAD_002: "",
      AAD_003: "",
      AAD_004: "",
      AAD_005: "",
      AAD_006: "",
      AAD_007: "",
      AAD_008: "",
      BAD_001_010: [],
      BAD_010: "",
      COS_001: "",
      COS_002: "",
      COS_003: "",
      COS_004: "",
      COS_005: "",
      TD_001: "",
      TD_002_013: [],
      TT_001: "",
      TT_002: "",
      TT_003: "",
      TT_004: "",
      TT_005: "",
      TT_006: "",
      TT_007: "",
      TT_008: "",
      TT_009: "",
      TT_010: "",
      TT_011: "",
      TT_012: "",
      ACT_001_008: [],
      DANIEL_001: "",
      DANIEL_002: "",
      DANIEL_003: "",
      DANIEL_004: "",
      DANIEL_005: "",
      DANIEL_006: "",
      MARIANA_001: "",
      MARIANA_002: "",
      MARIANA_003: "",
      MARIANA_004: "",
      MARIANA_005: "",
      MARIANA_006: "",
      SANDRA_001: "",
      SANDRA_002: "",
      SANDRA_003: "",
      SANDRA_004: "",
      SANDRA_005: "",
      SANDRA_006: "",
      CLAUDIA_001: "",
      CLAUDIA_002: "",
      CLAUDIA_003: "",
      CLAUDIA_004: "",
      CLAUDIA_005: "",
      CLAUDIA_006: "",
      AD_014: "",
      CON_001: "",
      CON_002: "",
      CON_003: "",
      CON_004: "",
      CON_005: "",
      CON_006: "",
      SAT_001: "",
    },

    validate: {
      IP_001_genero: (value) => validateField(value),
      IP_002_edad: (value) => validateField(value),
      IP_003_comunidad: (value) => validateField(value),
      IP_004_etnia: (value) => validateField(value),
      IL_001_contrato: (value) => validateField(value),
      IL_002_turno: (value) => validateField(value),
      IL_003_tiempoTrabajando: (value) => validateField(value),
      IL_004_area: (value) => validateField(value),
      IL_005_contactoConClientes: (value) => validateField(value),
      IL_006_puestoDeTrabajo: (value) => validateField(value),
      IL_007_participacionEnComite: (value) => validateField(value),
      IL_008_jefe: (value) => validateField(value),
      IL_009_centroDeTrabajo: (value) => validateField(value),
      SP_001_conocePoliticaDePrevencion: (value) => validateField(value),
      SP_002_haSidoCapacitado: (value) => validateField(value),
      SP_003_canalDeDenunciasExiste: (value) => validateField(value),
      SP_004_011_canalesDeDenuncias: (value) => validateCheckbox(value),
      SP_012_conoceProcesoDeInvestigacion: (value) => validateField(value),
      PL_001_percepcionLideres: (value) => validateField(value),
      TOL_001_bromasSexistas: (value) => validateField(value),
      TOL_002_bromasSobreIdentidadSexual: (value) => validateField(value),
      TOL_003_bromasSobreAparienciaFisica: (value) => validateField(value),
      TOL_004_invitarASalir: (value) => validateField(value),
      TOL_005_contactarPorRedesSociales: (value) => validateField(value),
      TOL_006_solicitarMaterialSexual: (value) => validateField(value),
      TOL_007_rozamientosIndebidos: (value) => validateField(value),
      TOL_008_tocamientosIndebidos: (value) => validateField(value),
      TOL_009_mirarConConnotacionSexual: (value) => validateField(value),
      TOL_010_invitarASalir: (value) => validateField(value),
      TOL_011_insinuarMejoresCondicionesACambioDeRelaciones: (value) =>
        validateField(value),
      TOL_012_ameazarConSuspenderContrato: (value) => validateField(value),
      CPT_001: (value) => validateField(value),
      CPT_002: (value) => validateField(value),
      CPT_003: (value) => validateField(value),
      CPT_004: (value) => validateField(value),
      AD_001_haSidoObjetoDeAcoso: (value) => validateField(value),
      AT_001_comentariosConContenidoSexual: (value) => validateField(value),
      AT_002_comentariosSobreOrientacionSexual: (value) => validateField(value),
      AT_003_comentariosSobreAparienciaFisica: (value) => validateField(value),
      AT_004_invitarASalir: (value) => validateField(value),
      AT_005_contactarPorRedesSociales: (value) => validateField(value),
      AT_006_solicitarMaterialSexual: (value) => validateField(value),
      AT_007_rozamientosIndebidos: (value) => validateField(value),
      AT_008_tocamientosIndebidos: (value) => validateField(value),
      AT_009_mirarConConnotacionSexual: (value) => validateField(value),
      AT_010_invitarASalir: (value) => validateField(value),
      AT_011_insinuarMejoresCondicionesACambioDeRelaciones: (value) =>
        validateField(value),
      AT_012_amenazarConSuspenderContrato: (value) => validateField(value),
      AD_002_013: (value) => validateCheckbox(value),
      FRE_001_frecuencia: (value) => validateField(value),
      PAD_001_002: (value) => validateCheckbox(value),
      PAD_003_007: (value) => validateCheckbox(value),
      AAD_001: (value) => validateField(value),
      AAD_002: (value) => validateField(value),
      AAD_003: (value) => validateField(value),
      AAD_004: (value) => validateField(value),
      AAD_005: (value) => validateField(value),
      AAD_006: (value) => validateField(value),
      AAD_007: (value) => validateField(value),
      AAD_008: (value) => validateField(value),
      BAD_001_010: (value) => validateCheckbox(value),
      BAD_010: (value) => {
        for (let i = 0; i < form.values.BAD_001_010.length; i++) {
          if (form.values.BAD_001_010[i] === "otro") {
            return validateField(value);
          }
        }
        return null;
      },
      COS_001: (value) => validateField(value),
      COS_002: (value) => validateField(value),
      COS_003: (value) => validateField(value),
      COS_004: (value) => validateField(value),
      COS_005: (value) => validateField(value),
      TD_001: (value) => validateField(value),
      TD_002_013: (value) => validateCheckbox(value),
      TT_001: (value) => validateField(value),
      TT_002: (value) => validateField(value),
      TT_003: (value) => validateField(value),
      TT_004: (value) => validateField(value),
      TT_005: (value) => validateField(value),
      TT_006: (value) => validateField(value),
      TT_007: (value) => validateField(value),
      TT_008: (value) => validateField(value),
      TT_009: (value) => validateField(value),
      TT_010: (value) => validateField(value),
      TT_011: (value) => validateField(value),
      TT_012: (value) => validateField(value),
      ACT_001_008: (value) => validateCheckbox(value),
      DANIEL_001: (value) => validateField(value),
      DANIEL_002: (value) => validateField(value),
      DANIEL_003: (value) => validateField(value),
      DANIEL_004: (value) => validateField(value),
      DANIEL_005: (value) => validateField(value),
      DANIEL_006: (value) => validateField(value),
      MARIANA_001: (value) => validateField(value),
      MARIANA_002: (value) => validateField(value),
      MARIANA_003: (value) => validateField(value),
      MARIANA_004: (value) => validateField(value),
      MARIANA_005: (value) => validateField(value),
      MARIANA_006: (value) => validateField(value),
      SANDRA_001: (value) => validateField(value),
      SANDRA_002: (value) => validateField(value),
      SANDRA_003: (value) => validateField(value),
      SANDRA_004: (value) => validateField(value),
      SANDRA_005: (value) => validateField(value),
      SANDRA_006: (value) => validateField(value),
      CLAUDIA_001: (value) => validateField(value),
      CLAUDIA_002: (value) => validateField(value),
      CLAUDIA_003: (value) => validateField(value),
      CLAUDIA_004: (value) => validateField(value),
      CLAUDIA_005: (value) => validateField(value),
      CLAUDIA_006: (value) => validateField(value),
      AD_014: (value) => validateField(value),
      CON_001: (value) => validateField(value),
      CON_002: (value) => validateField(value),
      CON_003: (value) => validateField(value),
      CON_004: (value) => validateField(value),
      CON_005: (value) => validateField(value),
      CON_006: (value) => validateField(value),
      SAT_001: (value) => validateField(value),
    },
  });

  const sendSurvey = async () => {
    setLoading(true);

    const addToDataIfNotEmpty = (data: any, key: string, value: any) => {
      if (value) data[key] = value;
    };
    const addToDataIfContains = (data: any, list: any, key: string) => {
      if (list.includes(key)) data[key] = true;
      else data[key] = false;
    };
    const checkIfCheckboxIsEmpty = (list: string[]) => {
      return list.every((element) => element === "");
    };

    if (survey) {
      const data: any = {};

      addToDataIfNotEmpty(data, "ip_001", form.values.IP_001_genero);
      addToDataIfNotEmpty(data, "ip_002", form.values.IP_002_edad);
      addToDataIfNotEmpty(data, "ip_003", form.values.IP_003_comunidad);
      addToDataIfNotEmpty(data, "ip_004", form.values.IP_004_etnia);
      addToDataIfNotEmpty(data, "il_001", form.values.IL_001_contrato);
      addToDataIfNotEmpty(
        data,
        "il_002",
        survey.work_shifts.filter(
          (shift) => shift.id === parseInt(form.values.IL_002_turno)
        )[0]?.description
      );
      addToDataIfNotEmpty(
        data,
        "work_shift",
        parseInt(form.values.IL_002_turno)
      );
      addToDataIfNotEmpty(data, "il_003", form.values.IL_003_tiempoTrabajando);
      addToDataIfNotEmpty(
        data,
        "il_004",
        survey.areas.filter(
          (area) => area.id === parseInt(form.values.IL_004_area)
        )[0]?.description
      );
      addToDataIfNotEmpty(data, "area", parseInt(form.values.IL_004_area));
      addToDataIfNotEmpty(
        data,
        "il_005",
        form.values.IL_005_contactoConClientes
      );
      addToDataIfNotEmpty(
        data,
        "il_006",
        survey.jobs.filter(
          (job) => job.id === parseInt(form.values.IL_006_puestoDeTrabajo)
        )[0]?.description
      );
      addToDataIfNotEmpty(
        data,
        "job",
        parseInt(form.values.IL_006_puestoDeTrabajo)
      );
      addToDataIfNotEmpty(
        data,
        "il_007",
        form.values.IL_007_participacionEnComite
      );
      addToDataIfNotEmpty(data, "il_008", form.values.IL_008_jefe);
      const workplace = survey.workplaces.filter(
        (workplace) =>
          workplace.id === parseInt(form.values.IL_009_centroDeTrabajo)
      )[0];
      if (workplace) addToDataIfNotEmpty(data, "il_009", workplace.description);
      addToDataIfNotEmpty(
        data,
        "workplace",
        parseInt(form.values.IL_009_centroDeTrabajo)
      );
      addToDataIfNotEmpty(
        data,
        "sp_001",
        form.values.SP_001_conocePoliticaDePrevencion
      );
      addToDataIfNotEmpty(data, "sp_002", form.values.SP_002_haSidoCapacitado);
      addToDataIfNotEmpty(
        data,
        "sp_003",
        form.values.SP_003_canalDeDenunciasExiste
      );

      if (!checkIfCheckboxIsEmpty(form.values.SP_004_011_canalesDeDenuncias)) {
        addToDataIfContains(
          data,
          form.values.SP_004_011_canalesDeDenuncias,
          "sp_004"
        );
        addToDataIfContains(
          data,
          form.values.SP_004_011_canalesDeDenuncias,
          "sp_005"
        );
        addToDataIfContains(
          data,
          form.values.SP_004_011_canalesDeDenuncias,
          "sp_006"
        );
        addToDataIfContains(
          data,
          form.values.SP_004_011_canalesDeDenuncias,
          "sp_007"
        );
        addToDataIfContains(
          data,
          form.values.SP_004_011_canalesDeDenuncias,
          "sp_008"
        );
        addToDataIfContains(
          data,
          form.values.SP_004_011_canalesDeDenuncias,
          "sp_09"
        );
        addToDataIfContains(
          data,
          form.values.SP_004_011_canalesDeDenuncias,
          "sp_010"
        );
        addToDataIfContains(
          data,
          form.values.SP_004_011_canalesDeDenuncias,
          "sp_011"
        );
      }

      addToDataIfNotEmpty(
        data,
        "sp_012",
        form.values.SP_012_conoceProcesoDeInvestigacion
      );

      addToDataIfNotEmpty(data, "pl_001", form.values.PL_001_percepcionLideres);

      addToDataIfNotEmpty(data, "tol_001", form.values.TOL_001_bromasSexistas);
      addToDataIfNotEmpty(
        data,
        "tol_002",
        form.values.TOL_002_bromasSobreIdentidadSexual
      );
      addToDataIfNotEmpty(
        data,
        "tol_003",
        form.values.TOL_003_bromasSobreAparienciaFisica
      );
      addToDataIfNotEmpty(data, "tol_004", form.values.TOL_004_invitarASalir);
      addToDataIfNotEmpty(
        data,
        "tol_005",
        form.values.TOL_005_contactarPorRedesSociales
      );
      addToDataIfNotEmpty(
        data,
        "tol_006",
        form.values.TOL_006_solicitarMaterialSexual
      );
      addToDataIfNotEmpty(
        data,
        "tol_007",
        form.values.TOL_007_rozamientosIndebidos
      );
      addToDataIfNotEmpty(
        data,
        "tol_008",
        form.values.TOL_008_tocamientosIndebidos
      );
      addToDataIfNotEmpty(
        data,
        "tol_009",
        form.values.TOL_009_mirarConConnotacionSexual
      );
      addToDataIfNotEmpty(data, "tol_010", form.values.TOL_010_invitarASalir);
      addToDataIfNotEmpty(
        data,
        "tol_011",
        form.values.TOL_011_insinuarMejoresCondicionesACambioDeRelaciones
      );
      addToDataIfNotEmpty(
        data,
        "tol_012",
        form.values.TOL_012_ameazarConSuspenderContrato
      );

      addToDataIfNotEmpty(data, "cpt_001", form.values.CPT_001);
      addToDataIfNotEmpty(data, "cpt_002", form.values.CPT_002);
      addToDataIfNotEmpty(data, "cpt_003", form.values.CPT_003);
      addToDataIfNotEmpty(data, "cpt_004", form.values.CPT_004);

      addToDataIfNotEmpty(
        data,
        "ad_001",
        form.values.AD_001_haSidoObjetoDeAcoso
      );
      if (!checkIfCheckboxIsEmpty(form.values.AD_002_013)) {
        addToDataIfContains(data, form.values.AD_002_013, "ad_002");
        addToDataIfContains(data, form.values.AD_002_013, "ad_003");
        addToDataIfContains(data, form.values.AD_002_013, "ad_004");
        addToDataIfContains(data, form.values.AD_002_013, "ad_005");
        addToDataIfContains(data, form.values.AD_002_013, "ad_006");
        addToDataIfContains(data, form.values.AD_002_013, "ad_007");
        addToDataIfContains(data, form.values.AD_002_013, "ad_008");
        addToDataIfContains(data, form.values.AD_002_013, "ad_009");
        addToDataIfContains(data, form.values.AD_002_013, "ad_010");
        addToDataIfContains(data, form.values.AD_002_013, "ad_011");
        addToDataIfContains(data, form.values.AD_002_013, "ad_012");
        addToDataIfContains(data, form.values.AD_002_013, "ad_012");
        addToDataIfContains(data, form.values.AD_002_013, "ad_013");
      }

      if (!checkIfCheckboxIsEmpty(form.values.PAD_001_002)) {
        addToDataIfContains(data, form.values.PAD_001_002, "pad_001");
        addToDataIfContains(data, form.values.PAD_001_002, "pad_002");
      }
      if (!checkIfCheckboxIsEmpty(form.values.PAD_003_007)) {
        addToDataIfContains(data, form.values.PAD_003_007, "pad_003");
        addToDataIfContains(data, form.values.PAD_003_007, "pad_004");
        addToDataIfContains(data, form.values.PAD_003_007, "pad_005");
        addToDataIfContains(data, form.values.PAD_003_007, "pad_006");
        addToDataIfContains(data, form.values.PAD_003_007, "pad_007");
      }

      addToDataIfNotEmpty(data, "aad_001", form.values.AAD_001);
      addToDataIfNotEmpty(data, "aad_002", form.values.AAD_002);
      addToDataIfNotEmpty(data, "aad_003", form.values.AAD_003);
      addToDataIfNotEmpty(data, "aad_004", form.values.AAD_004);
      addToDataIfNotEmpty(data, "aad_005", form.values.AAD_005);
      addToDataIfNotEmpty(data, "aad_006", form.values.AAD_006);
      addToDataIfNotEmpty(data, "aad_007", form.values.AAD_007);
      addToDataIfNotEmpty(data, "aad_008", form.values.AAD_008);

      if (!checkIfCheckboxIsEmpty(form.values.BAD_001_010)) {
        addToDataIfContains(data, form.values.BAD_001_010, "bad_001");
        addToDataIfContains(data, form.values.BAD_001_010, "bad_002");
        addToDataIfContains(data, form.values.BAD_001_010, "bad_003");
        addToDataIfContains(data, form.values.BAD_001_010, "bad_004");
        addToDataIfContains(data, form.values.BAD_001_010, "bad_005");
        addToDataIfContains(data, form.values.BAD_001_010, "bad_006");
        addToDataIfContains(data, form.values.BAD_001_010, "bad_007");
        addToDataIfContains(data, form.values.BAD_001_010, "bad_008");
        addToDataIfContains(data, form.values.BAD_001_010, "bad_009");

        const bad_list: string[] = form.values.BAD_001_010;
        if (bad_list.includes("otro"))
          addToDataIfNotEmpty(data, "bad_010", form.values.BAD_010);
      }

      addToDataIfNotEmpty(
        data,
        "at_001",
        form.values.AT_001_comentariosConContenidoSexual
      );
      addToDataIfNotEmpty(
        data,
        "at_002",
        form.values.AT_002_comentariosSobreOrientacionSexual
      );
      addToDataIfNotEmpty(
        data,
        "at_003",
        form.values.AT_003_comentariosSobreAparienciaFisica
      );
      addToDataIfNotEmpty(data, "at_004", form.values.AT_004_invitarASalir);
      addToDataIfNotEmpty(
        data,
        "at_005",
        form.values.AT_005_contactarPorRedesSociales
      );
      addToDataIfNotEmpty(
        data,
        "at_006",
        form.values.AT_006_solicitarMaterialSexual
      );
      addToDataIfNotEmpty(
        data,
        "at_007",
        form.values.AT_007_rozamientosIndebidos
      );
      addToDataIfNotEmpty(
        data,
        "at_008",
        form.values.AT_008_tocamientosIndebidos
      );
      addToDataIfNotEmpty(
        data,
        "at_009",
        form.values.AT_009_mirarConConnotacionSexual
      );
      addToDataIfNotEmpty(data, "at_010", form.values.AT_010_invitarASalir);
      addToDataIfNotEmpty(
        data,
        "at_011",
        form.values.AT_011_insinuarMejoresCondicionesACambioDeRelaciones
      );
      addToDataIfNotEmpty(
        data,
        "at_012",
        form.values.AT_012_amenazarConSuspenderContrato
      );

      addToDataIfNotEmpty(data, "fre_001", form.values.FRE_001_frecuencia);

      addToDataIfNotEmpty(data, "cos_001", form.values.COS_001);
      addToDataIfNotEmpty(data, "cos_002", form.values.COS_002);
      addToDataIfNotEmpty(data, "cos_003", form.values.COS_003);
      addToDataIfNotEmpty(data, "cos_004", form.values.COS_004);
      addToDataIfNotEmpty(data, "cos_005", form.values.COS_005);

      addToDataIfNotEmpty(data, "td_001", form.values.TD_001);
      if (!checkIfCheckboxIsEmpty(form.values.TD_002_013)) {
        addToDataIfContains(data, form.values.TD_002_013, "td_002");
        addToDataIfContains(data, form.values.TD_002_013, "td_003");
        addToDataIfContains(data, form.values.TD_002_013, "td_004");
        addToDataIfContains(data, form.values.TD_002_013, "td_005");
        addToDataIfContains(data, form.values.TD_002_013, "td_006");
        addToDataIfContains(data, form.values.TD_002_013, "td_007");
        addToDataIfContains(data, form.values.TD_002_013, "td_008");
        addToDataIfContains(data, form.values.TD_002_013, "td_009");
        addToDataIfContains(data, form.values.TD_002_013, "td_010");
        addToDataIfContains(data, form.values.TD_002_013, "td_011");
        addToDataIfContains(data, form.values.TD_002_013, "td_012");
        addToDataIfContains(data, form.values.TD_002_013, "td_013");
      }

      addToDataIfNotEmpty(data, "tt_001", form.values.TT_001);
      addToDataIfNotEmpty(data, "tt_002", form.values.TT_002);
      addToDataIfNotEmpty(data, "tt_003", form.values.TT_003);
      addToDataIfNotEmpty(data, "tt_004", form.values.TT_004);
      addToDataIfNotEmpty(data, "tt_005", form.values.TT_005);
      addToDataIfNotEmpty(data, "tt_006", form.values.TT_006);
      addToDataIfNotEmpty(data, "tt_007", form.values.TT_007);
      addToDataIfNotEmpty(data, "tt_008", form.values.TT_008);
      addToDataIfNotEmpty(data, "tt_009", form.values.TT_009);
      addToDataIfNotEmpty(data, "tt_010", form.values.TT_010);
      addToDataIfNotEmpty(data, "tt_011", form.values.TT_011);
      addToDataIfNotEmpty(data, "tt_012", form.values.TT_012);

      if (!checkIfCheckboxIsEmpty(form.values.ACT_001_008)) {
        addToDataIfContains(data, form.values.ACT_001_008, "act_001");
        addToDataIfContains(data, form.values.ACT_001_008, "act_002");
        addToDataIfContains(data, form.values.ACT_001_008, "act_003");
        addToDataIfContains(data, form.values.ACT_001_008, "act_004");
        addToDataIfContains(data, form.values.ACT_001_008, "act_005");
        addToDataIfContains(data, form.values.ACT_001_008, "act_006");
        addToDataIfContains(data, form.values.ACT_001_008, "act_007");
        addToDataIfContains(data, form.values.ACT_001_008, "act_008");
      }

      const addCase = (
        id: number,
        code: string,
        case_resolution: string,
        natural_heterosexuality: string,
        exacerbated_intent: string,
        victim_responsibility: string,
        overreaction: string,
        ulterior_motives: string
      ) => {
        data[`case_${code}`] = id;
        data[`case_${code}_case_resolution`] = case_resolution;
        data[`case_${code}_natural_heterosexuality`] = natural_heterosexuality;
        data[`case_${code}_exacerbated_intent`] = exacerbated_intent;
        data[`case_${code}_victim_responsibility`] = victim_responsibility;
        data[`case_${code}_overreaction`] = overreaction;
        data[`case_${code}_ulterior_motives`] = ulterior_motives;
      };

      if (isLeader || esParteDeRecursosHumanos) {
        addCase(
          survey.cases.first.id,
          "001",
          form.values.DANIEL_001,
          form.values.DANIEL_002,
          form.values.DANIEL_003,
          form.values.DANIEL_004,
          form.values.DANIEL_005,
          form.values.DANIEL_006
        );
        addCase(
          survey.cases.second.id,
          "002",
          form.values.MARIANA_001,
          form.values.MARIANA_002,
          form.values.MARIANA_003,
          form.values.MARIANA_004,
          form.values.MARIANA_005,
          form.values.MARIANA_006
        );
        addCase(
          survey.cases.third.id,
          "003",
          form.values.SANDRA_001,
          form.values.SANDRA_002,
          form.values.SANDRA_003,
          form.values.SANDRA_004,
          form.values.SANDRA_005,
          form.values.SANDRA_006
        );
        addCase(
          survey.cases.fourth.id,
          "004",
          form.values.CLAUDIA_001,
          form.values.CLAUDIA_002,
          form.values.CLAUDIA_003,
          form.values.CLAUDIA_004,
          form.values.CLAUDIA_005,
          form.values.CLAUDIA_006
        );
      }

      addToDataIfNotEmpty(data, "ad_014", form.values.AD_014);

      addToDataIfNotEmpty(data, "con_001", form.values.CON_001);
      addToDataIfNotEmpty(data, "con_002", form.values.CON_002);
      addToDataIfNotEmpty(data, "con_003", form.values.CON_003);
      addToDataIfNotEmpty(data, "con_004", form.values.CON_004);
      addToDataIfNotEmpty(data, "con_005", form.values.CON_005);
      addToDataIfNotEmpty(data, "con_006", form.values.CON_006);

      addToDataIfNotEmpty(data, "sat_001", form.values.SAT_001);

      console.table("to submit", data);

      if (mpCode && !props.previsualizar) {
        await SurveyService.post(mpCode, data);
        localStorage.setItem(mpCode, "true");
      }
    }

    setLoading(false);
  };

  const tituloPregunta = (id: string) =>
    `Situación ${id}. Por favor lee la situación que se describe a continuación y te haremos algunas preguntas al respecto.`;

  const handlePrev = () => {
    embla?.scrollPrev(false);
    form.resetDirty();
  };

  const handleNext = async (submit: boolean = false) => {
    const currentSlide = embla?.slideNodes()[embla?.selectedScrollSnap() || 0];
    const isInfoSlide = currentSlide?.classList.contains("info");

    if (isInfoSlide) {
      embla?.scrollNext(false);
    } else {
      if (currentSlide) {
        const slideId = currentSlide.id.split(",");

        let validated = true;
        slideId.forEach((id) => {
          form.validateField(id);
          if (!form.isValid(id)) validated = false;
        });

        if (validated) {
          if (submit) {
            if (!props.previsualizar) {
              try {
                await sendSurvey();
                navigate("/gracias");
              } catch {}
            }
          } else {
            embla?.scrollNext(false);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (embla) {
      setTotalSlides(embla.slideNodes().length);
    }
  }, [form, embla]);

  const [noDenuncio, setNoDenuncio] = useState(false);

  useEffect(() => {
    setNoDenuncio(form.values.AAD_008 === "No.");
  }, [form.values.AAD_008]);

  useEffect(() => {
    const getSurveyData = async () => {
      if (mpCode) {
        try {
          const surveyData = await SurveyService.get(mpCode);
          updateLogo(surveyData.client.logo);
          setSurvey(surveyData);
          console.log(surveyData.client.logo);
          if (!props.previsualizar) {
            if (surveyData.staff_survey.ended_at) {
              const fechaVencimiento = new Date(
                surveyData.staff_survey.ended_at
              );
              fechaVencimiento.setDate(fechaVencimiento.getDate() + 1);
              fechaVencimiento.setHours(fechaVencimiento.getHours() + 5);
              const fechaActual = new Date();
              // console.log(fechaVencimiento, fechaActual);
              if (fechaActual > fechaVencimiento) {
                setEncuestaVencida(true);
              }
            } else {
              setEncuestaTodaviaNoHaSidoLanzada(true);
            }
          }
        } catch (error) {
          console.log(error);
          setError("Error al cargar la encuesta. Revise su enlace.");
        }
      }
    };

    getSurveyData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) {
    return (
      <Container size={1200}>
        <Text>{error}</Text>
      </Container>
    );
  }

  const acosoTecnicoDeterminaQueSufrioAcoso = () => {
    const checkCond = (value: string) => value === "Sí, me ha pasado.";
    return (
      checkCond(form.values.AT_001_comentariosConContenidoSexual) ||
      checkCond(form.values.AT_002_comentariosSobreOrientacionSexual) ||
      checkCond(form.values.AT_003_comentariosSobreAparienciaFisica) ||
      checkCond(form.values.AT_004_invitarASalir) ||
      checkCond(form.values.AT_005_contactarPorRedesSociales) ||
      checkCond(form.values.AT_006_solicitarMaterialSexual) ||
      checkCond(form.values.AT_007_rozamientosIndebidos) ||
      checkCond(form.values.AT_008_tocamientosIndebidos) ||
      checkCond(form.values.AT_009_mirarConConnotacionSexual) ||
      checkCond(form.values.AT_010_invitarASalir) ||
      checkCond(
        form.values.AT_011_insinuarMejoresCondicionesACambioDeRelaciones
      ) ||
      checkCond(form.values.AT_012_amenazarConSuspenderContrato)
    );
  };

  const testigoTecnicoDeterminaQueFueTestigoDeAcoso = () => {
    const checkCond = (value: string) => value === "Sí, he sido testigo.";
    return (
      checkCond(form.values.TT_001) ||
      checkCond(form.values.TT_002) ||
      checkCond(form.values.TT_003) ||
      checkCond(form.values.TT_004) ||
      checkCond(form.values.TT_005) ||
      checkCond(form.values.TT_006) ||
      checkCond(form.values.TT_007) ||
      checkCond(form.values.TT_008) ||
      checkCond(form.values.TT_009) ||
      checkCond(form.values.TT_010) ||
      checkCond(form.values.TT_011) ||
      checkCond(form.values.TT_012)
    );
  };

  if (!mpCode) {
    return (
      <Container size={1200}>
        <Text>El enlace no es válido.</Text>
      </Container>
    );
  }

  if (survey && !encuestaVencida && !encuestaTodaviaNoHaSidoLanzada) {
    if (!localStorage.getItem(mpCode)) {
      return (
        <Container size={1200}>
          <form>
            <Carousel
              speed={90}
              mx="auto"
              getEmblaApi={setEmbla}
              withControls={false}
              draggable={false}
            >
              <Carousel.Slide className={`${classes.carouselSlide} info`}>
                <SurveyWelcome1 />
              </Carousel.Slide>
              <Carousel.Slide className={`${classes.carouselSlide} info`}>
                <SurveyWelcome2 />
              </Carousel.Slide>
              <Carousel.Slide className={`${classes.carouselSlide} info`}>
                <SurveyAcknowledgement />
              </Carousel.Slide>
              <Carousel.Slide
                className={classes.carouselSlide}
                id="IP_001_genero"
              >
                <Genero form={form} staff_survey={survey} />
              </Carousel.Slide>
              {survey.staff_survey.size === "large" && (
                <Carousel.Slide
                  className={classes.carouselSlide}
                  id="IP_002_edad"
                >
                  <Edad form={form} />
                </Carousel.Slide>
              )}
              {survey.staff_survey.size === "large" && (
                <Carousel.Slide
                  className={classes.carouselSlide}
                  id="IP_003_comunidad"
                >
                  <Comunidad form={form} />
                </Carousel.Slide>
              )}
              {survey.staff_survey.size === "large" && (
                <Carousel.Slide
                  className={classes.carouselSlide}
                  id="IP_004_etnia"
                >
                  <Etnia form={form} />
                </Carousel.Slide>
              )}
              {(survey.staff_survey.size === "medium" ||
                survey.staff_survey.size === "large") && (
                <Carousel.Slide
                  className={classes.carouselSlide}
                  id="IL_001_contrato"
                >
                  <Contrato form={form} />
                </Carousel.Slide>
              )}
              {survey.staff_survey.size === "large" && (
                <Carousel.Slide
                  className={classes.carouselSlide}
                  id="IL_002_turno"
                >
                  <Turno form={form} turnos={survey.work_shifts} />
                </Carousel.Slide>
              )}
              {survey.staff_survey.size === "large" && (
                <Carousel.Slide
                  className={classes.carouselSlide}
                  id="IL_003_tiempoTrabajando"
                >
                  <TiempoTrabajando form={form} />
                </Carousel.Slide>
              )}
              {(survey.staff_survey.size === "medium" ||
                survey.staff_survey.size === "large") && (
                <Carousel.Slide
                  className={classes.carouselSlide}
                  id="IL_004_area"
                >
                  <Area form={form} areas={survey.areas} />
                </Carousel.Slide>
              )}
              {(survey.staff_survey.size === "medium" ||
                survey.staff_survey.size === "large") && (
                <Carousel.Slide
                  className={classes.carouselSlide}
                  id="IL_005_contactoConClientes"
                >
                  <ContactoConClientes form={form} />
                </Carousel.Slide>
              )}
              <Carousel.Slide
                className={classes.carouselSlide}
                id="IL_006_puestoDeTrabajo"
              >
                <PuestoDeTrabajo
                  form={form}
                  puestos={survey.jobs}
                  setIsLeader={setIsLeader}
                />
              </Carousel.Slide>
              {(survey.staff_survey.size === "medium" ||
                survey.staff_survey.size === "large") && (
                <Carousel.Slide
                  className={classes.carouselSlide}
                  id="IL_007_participacionEnComite"
                >
                  <ParticipacionEnComite
                    form={form}
                    setParteDeRecursosHumanos={setParteDeRecursosHumanos}
                  />
                </Carousel.Slide>
              )}
              {(survey.staff_survey.size === "medium" ||
                survey.staff_survey.size === "large") && (
                <Carousel.Slide
                  className={classes.carouselSlide}
                  id="IL_008_jefe"
                >
                  <Jefe form={form} />
                </Carousel.Slide>
              )}
              {(survey.staff_survey.size === "medium" ||
                survey.staff_survey.size === "large") && (
                <Carousel.Slide
                  className={classes.carouselSlide}
                  id="IL_009_centroDeTrabajo"
                >
                  <CentroDeTrabajo form={form} centros={survey.workplaces} />
                </Carousel.Slide>
              )}
              <Carousel.Slide className={`${classes.carouselSlide} info`}>
                <SurveyInfoPolitics />
              </Carousel.Slide>
              <Carousel.Slide
                className={classes.carouselSlide}
                id="SP_001_conocePoliticaDePrevencion"
              >
                <ConocePoliticaDePrevencion form={form} />
              </Carousel.Slide>
              <Carousel.Slide
                className={classes.carouselSlide}
                id="SP_002_haSidoCapacitado"
              >
                <HaSidoCapacitado form={form} />
              </Carousel.Slide>
              <Carousel.Slide
                className={classes.carouselSlide}
                id="SP_003_canalDeDenunciasExiste"
              >
                <CanalDeDenunciasExiste form={form} />
              </Carousel.Slide>
              <Carousel.Slide
                className={classes.carouselSlide}
                id="SP_004_011_canalesDeDenuncias"
              >
                <CanalesDeDenuncias form={form} />
              </Carousel.Slide>
              <Carousel.Slide
                className={classes.carouselSlide}
                id="SP_012_conoceProcesoDeInvestigacion"
              >
                <ConoceProcesoDeInvestigacion form={form} />
              </Carousel.Slide>
              <Carousel.Slide className={`${classes.carouselSlide} info`}>
                <SurveyInfoSituations />
              </Carousel.Slide>
              {isLeader && (
                <Carousel.Slide
                  className={classes.carouselSlide}
                  id="PL_001_percepcionLideres"
                >
                  <PercepcionLideres form={form} />
                </Carousel.Slide>
              )}
              <Carousel.Slide
                className={classes.carouselSlide}
                id="TOL_001_bromasSexistas,TOL_002_bromasSobreIdentidadSexual,TOL_003_bromasSobreAparienciaFisica,TOL_004_invitarASalir,TOL_005_contactarPorRedesSociales,TOL_006_solicitarMaterialSexual,TOL_007_rozamientosIndebidos,TOL_008_tocamientosIndebidos,TOL_009_mirarConConnotacionSexual,TOL_010_invitarASalir,TOL_011_insinuarMejoresCondicionesACambioDeRelaciones,TOL_012_ameazarConSuspenderContrato"
              >
                <Tolerancia form={form} />
              </Carousel.Slide>
              <Carousel.Slide
                className={classes.carouselSlide}
                id="CPT_001,CPT_002,CPT_003,CPT_004"
              >
                <Conceptos form={form} />
              </Carousel.Slide>
              <Carousel.Slide className={`${classes.carouselSlide} info`}>
                <SurveyInfoPersonalExperience />
              </Carousel.Slide>
              <Carousel.Slide
                className={classes.carouselSlide}
                id="AD_001_haSidoObjetoDeAcoso"
              >
                <HaSidoObjetoDeAcoso
                  form={form}
                  setHaSidoObjetoDeAcoso={setHaSidoObjetoDeAcoso}
                />
              </Carousel.Slide>
              {haSidoObjetoDeAcoso && (
                <Carousel.Slide
                  className={classes.carouselSlide}
                  id="AD_002_013"
                >
                  <PreguntasAcosoDeclarado form={form} />
                </Carousel.Slide>
              )}
              {haSidoObjetoDeAcoso && (
                <>
                  <Carousel.Slide
                    className={classes.carouselSlide}
                    id="PAD_001_002"
                  >
                    <GeneroAcosador form={form} />
                  </Carousel.Slide>
                  <Carousel.Slide
                    className={classes.carouselSlide}
                    id="PAD_003_007"
                  >
                    <PreguntasPerfilAcosador form={form} />
                  </Carousel.Slide>
                </>
              )}
              {haSidoObjetoDeAcoso && (
                <Carousel.Slide
                  className={classes.carouselSlide}
                  id="AAD_001,AAD_002,AAD_003,AAD_004,AAD_005,AAD_006,AAD_007,AAD_008"
                >
                  <AccionesAcosoDeclarado form={form} />
                </Carousel.Slide>
              )}
              {haSidoObjetoDeAcoso && noDenuncio && (
                <Carousel.Slide
                  className={classes.carouselSlide}
                  id="BAD_001_010,BAD_010"
                >
                  <BarrerasDeDenuncia form={form} />
                </Carousel.Slide>
              )}
              {!haSidoObjetoDeAcoso && (
                <Carousel.Slide
                  className={classes.carouselSlide}
                  id="AT_001_comentariosConContenidoSexual,AT_002_comentariosSobreOrientacionSexual,AT_003_comentariosSobreAparienciaFisica,AT_004_invitarASalir,AT_005_contactarPorRedesSociales,AT_006_solicitarMaterialSexual,AT_007_rozamientosIndebidos,AT_008_tocamientosIndebidos,AT_009_mirarConConnotacionSexual,AT_010_invitarASalir,AT_011_insinuarMejoresCondicionesACambioDeRelaciones,AT_012_amenazarConSuspenderContrato"
                >
                  <PreguntasAcosoTecnico form={form} />
                </Carousel.Slide>
              )}
              {(haSidoObjetoDeAcoso ||
                acosoTecnicoDeterminaQueSufrioAcoso()) && (
                <Carousel.Slide
                  className={classes.carouselSlide}
                  id="FRE_001_frecuencia"
                >
                  <Frecuencia form={form} />
                </Carousel.Slide>
              )}
              {(haSidoObjetoDeAcoso ||
                acosoTecnicoDeterminaQueSufrioAcoso()) && (
                <Carousel.Slide
                  className={classes.carouselSlide}
                  id="COS_001,COS_002,COS_003,COS_004,COS_005"
                >
                  <Costos form={form} />
                </Carousel.Slide>
              )}
              <Carousel.Slide className={classes.carouselSlide} id="TD_001">
                <HaSidoTestigoDeAcoso
                  form={form}
                  setHaSidoTestigoDeAcoso={setHaSidoTestigoDeAcoso}
                />
              </Carousel.Slide>
              {haSidoTestigoDeAcoso && (
                <Carousel.Slide
                  className={classes.carouselSlide}
                  id="TD_002_013"
                >
                  <PreguntasTestigosDeclarados form={form} />
                </Carousel.Slide>
              )}
              {!haSidoTestigoDeAcoso && (
                <Carousel.Slide
                  className={classes.carouselSlide}
                  id="TT_001,TT_002,TT_003,TT_004,TT_005,TT_006,TT_007,TT_008,TT_009,TT_010,TT_011,TT_012"
                >
                  <PreguntasTestigosTecnicos form={form} />
                </Carousel.Slide>
              )}
              {(haSidoTestigoDeAcoso ||
                testigoTecnicoDeterminaQueFueTestigoDeAcoso()) && (
                <Carousel.Slide
                  className={classes.carouselSlide}
                  id="ACT_001_008"
                >
                  <PreguntasAccionesTestigos form={form} />
                </Carousel.Slide>
              )}
              {(isLeader || esParteDeRecursosHumanos) && (
                <>
                  <Carousel.Slide className={`${classes.carouselSlide} info`}>
                    <SurveyInfoCasos />
                  </Carousel.Slide>
                  <Carousel.Slide
                    className={classes.carouselSlide}
                    id="DANIEL_001,DANIEL_002,DANIEL_003,DANIEL_004,DANIEL_005,DANIEL_006"
                  >
                    <CasoTemplate
                      form={form}
                      caso={survey.cases.first}
                      title={tituloPregunta("1")}
                      questionIds={[
                        "DANIEL_001",
                        "DANIEL_002",
                        "DANIEL_003",
                        "DANIEL_004",
                        "DANIEL_005",
                        "DANIEL_006",
                      ]}
                    />
                  </Carousel.Slide>
                  <Carousel.Slide
                    className={classes.carouselSlide}
                    id="MARIANA_001,MARIANA_002,MARIANA_003,MARIANA_004,MARIANA_005,MARIANA_006"
                  >
                    <CasoTemplate
                      form={form}
                      caso={survey.cases.second}
                      title={tituloPregunta("2")}
                      questionIds={[
                        "MARIANA_001",
                        "MARIANA_002",
                        "MARIANA_003",
                        "MARIANA_004",
                        "MARIANA_005",
                        "MARIANA_006",
                      ]}
                    />
                  </Carousel.Slide>
                  <Carousel.Slide
                    className={classes.carouselSlide}
                    id="SANDRA_001,SANDRA_002,SANDRA_003,SANDRA_004,SANDRA_005,SANDRA_006"
                  >
                    <CasoTemplate
                      form={form}
                      caso={survey.cases.third}
                      title={tituloPregunta("3")}
                      questionIds={[
                        "SANDRA_001",
                        "SANDRA_002",
                        "SANDRA_003",
                        "SANDRA_004",
                        "SANDRA_005",
                        "SANDRA_006",
                      ]}
                    />
                  </Carousel.Slide>
                  <Carousel.Slide
                    className={classes.carouselSlide}
                    id="CLAUDIA_001,CLAUDIA_002,CLAUDIA_003,CLAUDIA_004,CLAUDIA_005,CLAUDIA_006"
                  >
                    <CasoTemplate
                      form={form}
                      caso={survey.cases.fourth}
                      title={tituloPregunta("4")}
                      questionIds={[
                        "CLAUDIA_001",
                        "CLAUDIA_002",
                        "CLAUDIA_003",
                        "CLAUDIA_004",
                        "CLAUDIA_005",
                        "CLAUDIA_006",
                      ]}
                    />
                  </Carousel.Slide>
                </>
              )}
              <Carousel.Slide className={classes.carouselSlide} id="AD_014">
                <HaSidoObjetoDeAcosoAlgunaVezEnSuVida form={form} />
              </Carousel.Slide>
              <Carousel.Slide className={`${classes.carouselSlide} info`}>
                <SurveyInfoTratamientoDeCasos />
              </Carousel.Slide>
              <Carousel.Slide
                className={classes.carouselSlide}
                id="CON_001,CON_002,CON_003,CON_004,CON_005,CON_006"
              >
                <PreguntasConfianzaEnLaOrganizacion form={form} />
              </Carousel.Slide>
              <Carousel.Slide className={classes.carouselSlide} id="SAT_001">
                <PreguntaSatisfaccion form={form} />
              </Carousel.Slide>
            </Carousel>
          </form>
          <Progress
            value={scrollProgress}
            styles={{
              bar: { transitionDuration: "0ms" },
              root: { maxWidth: "100%" },
            }}
            size="sm"
            mt="xl"
            mx="auto"
            color="#d92020"
          />
          <Group position="apart">
            <Text color="dimmed" size="xs">
              <>
                Página {(embla?.selectedScrollSnap() || 0) + 1} de {totalSlides}
              </>
            </Text>
            <SurveyNavigationButtons
              embla={embla}
              handlePrev={handlePrev}
              handleNext={handleNext}
              disabled={!survey || loading}
            />
          </Group>
        </Container>
      );
    } else {
      return (
        <Container size={1200}>
          Ya has completado la encuesta. ¡Gracias por tu tiempo!
        </Container>
      );
    }
  } else if (encuestaVencida) {
    return (
      <Container size={1200}>
        <Title order={3}>¡La encuesta ya finalizó!</Title>
        <Text>Este link no se encuentra disponible.</Text>
      </Container>
    );
  } else if (encuestaTodaviaNoHaSidoLanzada) {
    return (
      <Container size={1200}>
        <Title order={3}>¡La encuesta todavía no ha sido lanzada!</Title>
        <Text>Este link no se encuentra disponible.</Text>
      </Container>
    );
  } else {
    return (
      <Container size={1200} h={550}>
        <Flex justify="center" align="center" h="100%">
          <Loader color="#d92020" />
        </Flex>
      </Container>
    );
  }
};
