import { MultiRadioTable } from "../../components/MultiRadioTable";

type Props = {
  form: any;
};

const Component = ({ form }: Props) => {
  return (
    <MultiRadioTable
      form={form}
      label={"¿Qué piensas de las siguientes situaciones en el trabajo?"}
      options={[
        "Es hostigamiento o acoso, pero no es sexual.",
        "No veo nada de malo con esa situación.",
        "Es hostigamiento o acoso sexual en el trabajo.",
        "No son formas de comportarse en el trabajo, pero no es acoso.",
      ]}
      questions={[
        "Hacer comentarios o bromas de contenido sexual o sexista.",
        "Hacer comentarios o bromas sobre la orientación sexual o identidad de género de una persona.",
        "Hacer comentarios sobre la apariencia física o cuerpo de una persona que generan incomodidad, incluyendo también gestos, silbidos o sonidos.",
        "Invitar reiteradamente a salir a una persona a pesar de no recibir respuesta o no recibir respuesta positiva.",
        "Contactar reiteradamente a través de mensajes electrónicos, chats, WhatsApp, Facebook, entre otros, con fines no laborales que generan incomodidad.",
        "Solicitar o enviar sin consentimiento fotos o videos de material sexual explícito o de connotación sexual.",
        "Acercarse demasiado físicamente o tocar (rozar, sobar, masajear, etc.) sin necesidad a una persona en alguna parte del cuerpo como hombros, espalda baja, rodilla u otras partes.",
        "Tocar o rozar a una persona en zonas privadas del cuerpo como los senos, genitales, etc.",
        "Mirar persistentemente con connotación sexual a una persona.",
        "Que un jefe/a se insinúe y/o realice invitaciones a salir a pesar que de alguna manera se le mostró incomodidad.",
        "Insinuar o proponer mejoras en la situación laboral de una persona a cambio de aceptar invitaciones, acercamientos o encuentros sexuales.",
        "Amenazar o insinuar con despedir a una persona, retirarle los beneficios laborales o tomar alguna represalia por no aceptar invitaciones, acercamientos, o encuentros sexuales.",
      ]}
      questionIds={[
        "TOL_001_bromasSexistas",
        "TOL_002_bromasSobreIdentidadSexual",
        "TOL_003_bromasSobreAparienciaFisica",
        "TOL_004_invitarASalir",
        "TOL_005_contactarPorRedesSociales",
        "TOL_006_solicitarMaterialSexual",
        "TOL_007_rozamientosIndebidos",
        "TOL_008_tocamientosIndebidos",
        "TOL_009_mirarConConnotacionSexual",
        "TOL_010_invitarASalir",
        "TOL_011_insinuarMejoresCondicionesACambioDeRelaciones",
        "TOL_012_ameazarConSuspenderContrato",
      ]}
    />
  );
};

export default Component;
