import { Text, useMantineTheme } from "@mantine/core";

type Props = {
  children: string;
};

export const LabelText = ({ children }: Props) => {
  const theme = useMantineTheme();
  return (
    <Text
      size={14}
      fw={500}
      color={theme.colorScheme === "light" ? "#212529" : "#C1C2C5"}
    >
      {children}
    </Text>
  );
};
