import { Radio } from "@mantine/core";
import { RadioSurvey } from "../../components";

type Props = {
  form: any;
};

const Component = ({ form }: Props) => {
  return (
    <RadioSurvey
      label="¿Conoces la política de prevención y/o sanción de hostigamiento o acoso sexual laboral en tu institución o empresa?"
      {...form.getInputProps("SP_001_conocePoliticaDePrevencion")}
    >
      <Radio
        label="Sé que existe pero no la he revisado."
        value="Sé que existe pero no la he revisado."
      />
      <Radio label="No." value="No." />
      <Radio label="Sí, la he revisado." value="Sí, la he revisado." />
    </RadioSurvey>
  );
};

export default Component;
