import { Radio } from "@mantine/core";
import { RadioSurvey } from "../../components";

type Props = {
  form: any;
};

const Component = ({ form }: Props) => {
  return (
    <RadioSurvey
      label="En general, ¿con qué frecuencia se han dado estas situaciones de hostigamiento o acoso sexual?"
      {...form.getInputProps("FRE_001_frecuencia")}
    >
      <Radio
        label="Con muy baja frecuencia."
        value="Con muy baja frecuencia."
      />
      <Radio label="Con poca frecuencia." value="Con poca frecuencia." />
      <Radio label="Con algo de frecuencia." value="Con algo de frecuencia." />
      <Radio label="Con mucha frecuencia." value="Con mucha frecuencia." />
    </RadioSurvey>
  );
};

export default Component;
