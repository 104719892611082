import React from "react";
import { Radio, Stack } from "@mantine/core";

type Props = {
  label: string;
  description?: string;
  children: React.ReactNode;
};

const RadioSurvey = ({ label, children, description, ...props }: Props) => {
  return (
    <Stack spacing={1}>
      {/* <Text fz="xs" color="dimmed">
        Esta pregunta es de opción única.
      </Text> */}
      <Radio.Group
        orientation="vertical"
        errorProps={{ mt: "xs" }}
        label={label}
        description={description}
        {...props}
      >
        {children}
      </Radio.Group>
    </Stack>
  );
};

export default RadioSurvey;
