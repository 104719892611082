import { Radio } from "@mantine/core";
import { RadioSurvey } from "../../components";

type Props = {
  form: any;
  setHaSidoObjetoDeAcoso: (haSidoObjetoDeAcoso: boolean) => void;
};

const Component = ({ form, setHaSidoObjetoDeAcoso }: Props) => {
  return (
    <RadioSurvey
      label="Esta pregunta se refiere SOLO A TU TRABAJO ACTUAL. ¿En los últimos 12 meses has sido objeto de una situación de hostigamiento o acoso sexual laboral?"
      {...form.getInputProps("AD_001_haSidoObjetoDeAcoso")}
    >
      <Radio
        label="No me ha pasado."
        value="No me ha pasado."
        onClick={() => setHaSidoObjetoDeAcoso(false)}
      />
      <Radio
        label="Sí, me ha pasado."
        value="Sí, me ha pasado."
        onClick={() => setHaSidoObjetoDeAcoso(true)}
      />
      <Radio
        label="No estoy seguro/a."
        value="No estoy seguro/a."
        onClick={() => setHaSidoObjetoDeAcoso(false)}
      />
    </RadioSurvey>
  );
};

export default Component;
