import { Checkbox, Stack, Text } from "@mantine/core";
import React from "react";

type Props = {
  label: string;
  description?: string;
  children: React.ReactNode;
};

const CheckboxSurvey = ({ label, description, children, ...props }: Props) => {
  return (
    <Stack spacing={1}>
      <Text fz="xs" color="dimmed">
        Puedes marcar más de una opción en esta pregunta.
      </Text>
      <Checkbox.Group
        label={label}
        errorProps={{ mt: "xs" }}
        orientation="vertical"
        {...props}
      >
        {children}
      </Checkbox.Group>
    </Stack>
  );
};

export default CheckboxSurvey;
