import { Radio } from "@mantine/core";
import { TStaffSurveyCustomField } from "../../types";
import { RadioSurvey } from "../../components";

type Props = {
  form: any;
  centros: TStaffSurveyCustomField[];
};

const Component = ({ form, centros }: Props) => {
  return (
    <RadioSurvey
      label="¿Cuál es tu centro de trabajo? (Marca la opción que más se ajuste a tu situación actual)"
      {...form.getInputProps("IL_009_centroDeTrabajo")}
    >
      {centros.map((centro) => (
        <Radio
          label={centro.description}
          value={centro.id.toString()}
          key={centro.id}
        />
      ))}
    </RadioSurvey>
  );
};

export default Component;
