import { Checkbox, TextInput } from "@mantine/core";
import CheckboxSurvey from "../../components/form/Checkbox";

type Props = {
  form: any;
};

const Component = ({ form }: Props) => {
  return (
    <>
      <CheckboxSurvey
        label="Por favor cuéntanos por qué decidiste no hacer una denuncia ni reportarlo."
        {...form.getInputProps("BAD_001_010")}
      >
        <Checkbox
          label="Pienso que no sirve de nada denunciar."
          value="bad_001"
        />
        <Checkbox
          label="Nada, me pareció que no fue de mala intención."
          value="bad_002"
        />
        <Checkbox label="Tenía vergüenza." value="bad_003" />
        <Checkbox
          label="No sabía o no existe un canal de denuncia para estos casos."
          value="bad_004"
        />
        <Checkbox
          label="No quería ser vista como una persona problemática en el trabajo."
          value="bad_005"
        />
        <Checkbox
          label="No quería ocasionarle problemas a la persona."
          value="bad_006"
        />
        <Checkbox
          label="Tenía miedo de perder mi trabajo o de sufrir algún otro tipo de represalia."
          value="bad_007"
        />
        <Checkbox
          label="Tenía miedo de tener problemas con mi pareja."
          value="bad_008"
        />
        <Checkbox
          label="En ese momento no estaba seguro/a de si era un caso de hostigamiento o acoso sexual laboral."
          value="bad_009"
        />
        <Checkbox label="Otro (especificar)" value="otro" />
      </CheckboxSurvey>
      {form.values.BAD_001_010.includes("otro") && (
        <TextInput
          mt="lg"
          width={400}
          placeholder="Detalla aquí"
          {...form.getInputProps("BAD_010")}
        />
      )}
    </>
  );
};

export default Component;
