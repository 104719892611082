import { Radio } from "@mantine/core";
import { RadioSurvey } from "../../components";

type Props = {
  form: any;
};

const Component = ({ form }: Props) => {
  return (
    <RadioSurvey
      label="¿Qué tipo de contrato tienes con tu empresa o institución?"
      {...form.getInputProps("IL_001_contrato")}
    >
      <Radio label="Convenio de prácticas." value="Convenio de prácticas." />
      <Radio
        label="Contrato o vínculo laboral a plazo fijo (tiene fecha de vencimiento)."
        value="Contrato o vínculo laboral a plazo fijo (tiene fecha de vencimiento)."
      />
      <Radio
        label="Contrato o vínculo laboral a plazo indeterminado o indefinido (no vence)."
        value="Contrato o vínculo laboral a plazo indeterminado o indefinido (no vence)."
      />
    </RadioSurvey>
  );
};

export default Component;
