import { TStaffSurveyData } from "../types";
import { ApiWithoutToken } from "./axios-instance";

export const SurveyService = {
  get: async (mpCode: string) => {
    const response = await ApiWithoutToken.get(
      `api/measurement_process/${mpCode}/staff_survey/survey/`
    );
    const data: TStaffSurveyData = response.data;
    return data;
  },
  post: async (mpCode: string, data: any) =>
    await ApiWithoutToken.post(
      `api/measurement_process/${mpCode}/staff_survey/survey/`,
      data
    ),
};
