import { Radio } from "@mantine/core";
import { RadioSurvey } from "../../components";

type Props = {
  form: any;
};

const Component = ({ form }: Props) => {
  return (
    <RadioSurvey
      label="En general, ¿qué tan satisfecho/a te encuentras en la empresa o institución en la que trabajas actualmente?"
      {...form.getInputProps("SAT_001")}
    >
      <Radio
        label="Totalmente insatisfecho/a."
        value="Totalmente insatisfecho/a."
      />
      <Radio label="Insatisfecho/a." value="Insatisfecho/a." />
      <Radio label="Satisfecho/a." value="Satisfecho/a." />
      <Radio
        label="Totalmente satisfecho/a."
        value="Totalmente satisfecho/a."
      />
    </RadioSurvey>
  );
};

export default Component;
