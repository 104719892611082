import { Radio } from "@mantine/core";
import { RadioSurvey } from "../../components";

type Props = {
  form: any;
};

const Component = ({ form }: Props) => {
  return (
    <RadioSurvey
      label="¿Te identificas como parte de la comunidad LGBTIQ+ (lesbiana, gay, bisexual, transgénero, transexual, travesti, intersexual, queer y otras)?"
      description="Respetamos tu privacidad, pero recuerda que este es un espacio seguro y libre de discriminación, y mantendremos ésta y todas tus respuestas en absoluta confidencialidad. Si prefieres no responder a esta pregunta, marca “prefiero no responder”"
      {...form.getInputProps("IP_003_comunidad")}
    >
      <Radio label="Sí." value="Sí." />
      <Radio label="No." value="No." />
      <Radio label="Prefiero no responder." value="Prefiero no responder." />
    </RadioSurvey>
  );
};

export default Component;
