import { Radio } from "@mantine/core";
import { RadioSurvey } from "../../components";

type Props = {
  form: any;
};

const Component = ({ form }: Props) => {
  return (
    <RadioSurvey
      label="¿Hace cuánto tiempo trabajas para tu empleador?"
      {...form.getInputProps("IL_003_tiempoTrabajando")}
    >
      <Radio label="Hace más de 10 años." value="Hace más de 10 años." />
      <Radio label="Entre 5 y 10 años." value="Entre 5 y 10 años." />
      <Radio label="Entre 1 y 5 años." value="Entre 1 y 5 años." />
      <Radio label="Entre 3 meses y 1 año." value="Entre 3 meses y 1 año." />
      <Radio label="Menos de 3 meses." value="Menos de 3 meses." />
    </RadioSurvey>
  );
};

export default Component;
