import { Radio } from "@mantine/core";
import { RadioSurvey } from "../../components";

type Props = {
  form: any;
};

const Component = ({ form }: Props) => {
  return (
    <RadioSurvey
      label="Tu jefe/a inmediato es:"
      {...form.getInputProps("IL_008_jefe")}
    >
      <Radio label="Hombre." value="Hombre." />
      <Radio label="Mujer." value="Mujer." />
    </RadioSurvey>
  );
};

export default Component;
