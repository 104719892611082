import { Container, createStyles, Space, Text, Title } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  list: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing.md,
  },
}));

export const SurveyWelcome1 = () => {
  const { classes } = useStyles();
  return (
    <Container fluid>
      <Title>¡Hola!</Title>
      <Space h="md" />
      <Text ta="justify">
        Te presentamos ELSA, una herramienta digital que construye espacios
        laborales sin acoso sexual. ELSA ha sido financiada por el Banco
        Interamericano de Desarrollo (BID), desarrollada por GenderLab e
        implementada con el apoyo del gobierno, los gremios empresariales y tu
        empleador.
      </Text>
      <Space h="md" />
      <Text>
        Agradecemos tu participación en esta encuesta y queremos comentarte
        algunas cosas para que puedas continuar.
      </Text>
      <Space h="md" />
      <Text fw={700}>Propósito de la encuesta</Text>
      <ul className={classes.list}>
        <li>
          <Text ta="justify">
            El objeto de esta encuesta es hacer un diagnóstico organizacional
            que nos permita recoger información básica sobre la organización,
            como el balance de género en la organización, políticas que tienen
            implementadas, los canales de apoyo en casos de acoso, las
            capacitaciones que dictan a diferentes grupos en la organización así
            como su contenido y metodología.
          </Text>
        </li>
        <li>
          <Text ta="justify">
            Con toda la información recogida más la información agregada de las
            personas que trabajan en tu organización se elaborará un informe de
            resultados confidencial que te permitirá adoptar las mejores medidas
            para impulsar una cultura de prevención del acoso sexual laboral.
          </Text>
        </li>
        <li>
          <Text ta="justify">
            Adicionalmente, las respuestas agregadas de todas las organizaciones
            serán utilizadas para realizar informes por sectores económicos y
            países. Esta información podrá ser también utilizada para realizar
            investigaciones y publicaciones académicas sobre cómo crear espacios
            laborales sin acoso.
          </Text>
        </li>
      </ul>
    </Container>
  );
};
