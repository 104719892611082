import { Radio } from "@mantine/core";
import { RadioSurvey } from "../../components";

type Props = {
  form: any;
  setParteDeRecursosHumanos: (value: boolean) => void;
};

const Component = ({ form, setParteDeRecursosHumanos }: Props) => {
  return (
    <RadioSurvey
      label="¿Eres parte del Comité de Ética, Comité de Convivencia, Comité de Intervención frente al Hostigamiento Sexual Laboral o del área de Recursos Humanos de tu organización?"
      {...form.getInputProps("IL_007_participacionEnComite")}
    >
      <Radio
        label="Sí."
        value="Sí."
        onClick={() => setParteDeRecursosHumanos(true)}
      />
      <Radio
        label="No."
        value="No."
        onClick={() => setParteDeRecursosHumanos(false)}
      />
    </RadioSurvey>
  );
};

export default Component;
