import { Container, Text, Title } from "@mantine/core";

export const SurveyFinalThankYou = () => {
  return (
    <Container size={1200}>
      <Title>¡Muchas gracias por tu tiempo, terminaste la encuesta!</Title>
      <Text>
        Junto con tu empresa o institución eres parte del esfuerzo por crear
        espacios laborales sin acoso, #ELSA.
      </Text>
    </Container>
  );
};
