import { Space, Text } from "@mantine/core";

const Component = () => {
  return (
    <>
      <Text ta="justify">
        Gracias por llegar hasta acá, tu aporte es importante para construir
        espacios de trabajo basados en el respeto.
      </Text>
      <Space h="md" />
      <Text ta="justify">
        A continuación te haremos algunas preguntas relacionadas con tu{" "}
        <span style={{ fontWeight: 500, textDecoration: "underline" }}>
          experiencia personal
        </span>
        . Toma en cuenta solo los últimos 12 meses y la empresa o institución en
        la que trabajas actualmente.
      </Text>
      <Space h="md" />
      <Text ta="justify">Te recordamos que la encuesta es anónima.</Text>
    </>
  );
};

export default Component;
