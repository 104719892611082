import { Space, Text } from "@mantine/core";

const Component = () => {
  return (
    <>
      <Text ta="justify">
        Estamos entrando a la recta final, ¡gracias por ser parte!
      </Text>
      <Space h="md" />
      <Text ta="justify">
        Ahora te vamos a poner cuatro casos prácticos y algunas preguntas sobre
        ellos. Agradecemos que seas totalmente sincero/a con tus respuestas. Tu
        sinceridad nos ayuda a crear una gran comunidad de aprendizaje.
      </Text>
    </>
  );
};

export default Component;
