import {
  Container,
  createStyles,
  Flex,
  Header,
  Image,
  useMantineTheme,
} from "@mantine/core";
import { ColorSchemeToggle } from "./ColorSchemeToggle";
import { useLogo } from "../context/Logo/LogoContext";

const useStyles = createStyles(() => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
  },
}));

export const GenderlabHeader = () => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const { logo } = useLogo();

  return (
    <Header height={100} mb={20}>
      <Container className={classes.header} size={1200}>
        <Flex gap="lg">
          <Image
            alt="logo"
            src={
              theme.colorScheme === "light"
                ? "/img/elsa-light.png"
                : "/img/elsa-dark.png"
            }
            width={250}
          />
          <ColorSchemeToggle />
        </Flex>
        {logo && <Image alt="empresa-logo" src={logo} width={80} />}
      </Container>
    </Header>
  );
};
