import { Text } from "@mantine/core";

const Component = () => {
  return (
    <Text ta="justify">
      Muchas gracias. Estamos por terminar. A continuación, te realizaremos unas
      preguntas sobre el tratamiento de casos de hostigamiento o acoso sexual
      laboral en tu organización. Recuerda que todas tus respuestas son
      totalmente anónimas y confidenciales.
    </Text>
  );
};

export default Component;
