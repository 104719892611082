import { Checkbox } from "@mantine/core";
import CheckboxSurvey from "../../components/form/Checkbox";

type Props = {
  form: any;
};

const Component = ({ form }: Props) => {
  return (
    <CheckboxSurvey
      label="¿Qué relación tenías con la persona que realizó esta(s) conducta(s)?"
      {...form.getInputProps("PAD_003_007")}
    >
      <Checkbox
        label="Era un trabajador/a de otra organización (por ejemplo, empresa contratista o empresa cliente)."
        value="pad_007"
      />
      <Checkbox
        label="Era un cliente/a o usuario/a de mi organización."
        value="pad_006"
      />
      <Checkbox label="Era mi subordinado/a." value="pad_005" />
      <Checkbox
        label="Era mi jefe/a u otra persona de nivel jerárquico superior."
        value="pad_004"
      />
      <Checkbox
        label="Era mi colega o compañero/a de trabajo."
        value="pad_003"
      />
    </CheckboxSurvey>
  );
};

export default Component;
