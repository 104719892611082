import { Container, Title, Text } from "@mantine/core";
import React from "react";

type Props = {};

export const NotFoundPage = (props: Props) => {
  return (
    <Container>
      <Title>Página no encontrada</Title>
      <Text>Asegúrese de haber ingresado al link provisto por GenderLab.</Text>
      <Text>Contacte a soporte@genderlab.io para más información.</Text>
    </Container>
  );
};
