import { Radio } from "@mantine/core";
import { TStaffSurveyCustomField } from "../../types";
import { RadioSurvey } from "../../components";

type Props = {
  form: any;
  areas: TStaffSurveyCustomField[];
};

const Component = ({ form, areas }: Props) => {
  return (
    <RadioSurvey
      label="¿En qué área trabajas?"
      {...form.getInputProps("IL_004_area")}
    >
      {areas &&
        areas.map((area) => (
          <Radio
            label={area.description}
            value={area.id.toString()}
            key={area.id}
          />
        ))}
    </RadioSurvey>
  );
};

export default Component;
