import { MultiRadioTable } from "../../components/MultiRadioTable";

type Props = {
  form: any;
};

const Component = ({ form }: Props) => {
  return (
    <MultiRadioTable
      form={form}
      label="Para que haya hostigamiento o acoso sexual laboral:"
      options={["Sí.", "No."]}
      questions={[
        "¿La persona afectada debe mostrar rechazo expreso?",
        "¿La situación debe haber ocurrido más de una vez?",
        "¿Debe existir una relación de jerarquía entre quien realiza la conducta y quien se ve afectado/a?",
        "Quien realiza la conducta, ¿debe tener la intención clara de acosar?",
      ]}
      questionIds={["CPT_001", "CPT_002", "CPT_004", "CPT_003"]}
    />
  );
};

export default Component;
