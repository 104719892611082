import { Radio } from "@mantine/core";
import { TStaffSurveyJob } from "../../types";
import { RadioSurvey } from "../../components";

type Props = {
  form: any;
  puestos: TStaffSurveyJob[];
  setIsLeader: (isLeader: boolean) => void;
};

const Component = ({ form, puestos, setIsLeader }: Props) => {
  return (
    <RadioSurvey
      label="¿Cuál es tu puesto de trabajo?"
      {...form.getInputProps("IL_006_puestoDeTrabajo")}
    >
      {puestos &&
        puestos.map((puesto) => (
          <Radio
            label={puesto.description}
            value={puesto.id.toString()}
            key={puesto.id}
            onClick={() => setIsLeader(puesto.is_leader)}
          />
        ))}
    </RadioSurvey>
  );
};

export default Component;
