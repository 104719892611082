import { Radio } from "@mantine/core";
import { RadioSurvey } from "../../components";

type Props = {
  form: any;
  setHaSidoTestigoDeAcoso: (value: boolean) => void;
};

const Component = ({ form, setHaSidoTestigoDeAcoso }: Props) => {
  return (
    <RadioSurvey
      label="En los últimos 12 meses, ¿has sido testigo de algún caso de hostigamiento o acoso sexual?"
      {...form.getInputProps("TD_001")}
    >
      <Radio
        label="No estoy seguro/a."
        value="No estoy seguro/a."
        onClick={() => setHaSidoTestigoDeAcoso(false)}
      />
      <Radio
        label="Sí, he sido testigo de hostigamiento o acoso sexual."
        value="Sí, he sido testigo de hostigamiento o acoso sexual."
        onClick={() => setHaSidoTestigoDeAcoso(true)}
      />
      <Radio
        label="No conozco ni he sido testigo de ningún acoso."
        value="No conozco ni he sido testigo de ningún acoso."
        onClick={() => setHaSidoTestigoDeAcoso(false)}
      />
    </RadioSurvey>
  );
};

export default Component;
