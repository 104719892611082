import {
  Divider,
  Flex,
  Text,
  Image,
  Container,
  useMantineTheme,
} from "@mantine/core";

export const GenderlabFooter = () => {
  const theme = useMantineTheme();
  return (
    <>
      <Divider mt="xl" />
      <Container size={1200} mb="xl">
        <Flex justify="flex-end" align="center">
          <Flex justify="end" align="center" mt="lg" gap="xs">
            <Text>Con el apoyo del</Text>
            <Image
              width={80}
              src={
                theme.colorScheme === "light"
                  ? "/img/bid-logo.png"
                  : "/img/bid-logo-dark.png"
              }
            />
          </Flex>
        </Flex>
      </Container>
    </>
  );
};
