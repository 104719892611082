import { Radio } from "@mantine/core";
import { RadioSurvey } from "../../components";

type Props = {
  form: any;
};

const Component = ({ form }: Props) => {
  return (
    <RadioSurvey
      label="Seleccione su edad"
      {...form.getInputProps("IP_002_edad")}
    >
      <Radio label="60 años o más." value="60 años o más." />
      <Radio label="50 - 59 años." value="50 - 59 años." />
      <Radio label="30 - 49 años." value="30 - 49 años." />
      <Radio label="18 - 29 años." value="18 - 29 años." />
      <Radio label="Prefiero no responder." value="Prefiero no responder." />
    </RadioSurvey>
  );
};

export default Component;
