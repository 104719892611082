import { Embla } from "@mantine/carousel";
import { Button, Grid, Loader } from "@mantine/core";
import { useEffect } from "react";

type Props = {
  embla: Embla | null;
  handlePrev: () => void;
  handleNext: (submit: boolean) => void;
  disabled: boolean;
};

export const SurveyNavigationButtons = ({
  embla,
  handlePrev,
  handleNext,
  disabled,
}: Props) => {
  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === "Enter") {
      document.getElementById("next-button")?.click();
      if (!embla?.canScrollNext()) {
        document.getElementById("submit-button")?.click();
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid justify="flex-end" align="center" mt="lg">
      {/* {embla?.canScrollPrev() && (
        <Grid.Col span="content">
          <Button onClick={handlePrev} size="xs" disabled={disabled}>
            Atrás
          </Button>
        </Grid.Col>
      )} */}
      {embla?.canScrollNext() && (
        <Grid.Col span="content">
          <Button
            id="next-button"
            onClick={() => {
              handleNext(!embla?.canScrollNext());
            }}
            size="xs"
            disabled={disabled}
          >
            Siguiente
          </Button>
        </Grid.Col>
      )}
      {!embla?.canScrollNext() && (
        <Grid.Col span="content">
          <Button
            id="submit-button"
            onClick={() => handleNext(true)}
            size="xs"
            disabled={disabled}
            style={{ backgroundColor: "#1ab394", color: "white" }}
          >
            {!disabled ? "Finalizar" : <Loader size="xs" />}
          </Button>
        </Grid.Col>
      )}
    </Grid>
  );
};
