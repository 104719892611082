import { Radio } from "@mantine/core";
import { RadioSurvey } from "../../components";

type Props = {
  form: any;
};

const Component = ({ form }: Props) => {
  return (
    <RadioSurvey
      label="Por tus antepasados y de acuerdo a tus costumbres, te consideras:"
      description="Respetamos tu privacidad, pero recuerda que este es un espacio seguro y libre de discriminación, y mantendremos ésta y todas tus respuestas en absoluta confidencialidad. Si prefieres no responder a esta pregunta, marca “prefiero no responder”"
      {...form.getInputProps("IP_004_etnia")}
    >
      <Radio label="Asiático/a." value="Asiático/a." />
      <Radio label="Afrodescendiente." value="Afrodescendiente." />
      <Radio label="Blanco/a." value="Blanco/a." />
      <Radio label="Indígena." value="Indígena." />
      <Radio label="Mestizo/a." value="Mestizo/a." />
      <Radio label="Otro." value="Otro." />
      <Radio label="Prefiero no responder." value="Prefiero no responder." />
    </RadioSurvey>
  );
};

export default Component;
