import { Checkbox } from "@mantine/core";
import CheckboxSurvey from "../../components/form/Checkbox";

type Props = {
  form: any;
};

const Component = ({ form }: Props) => {
  return (
    <CheckboxSurvey
      label="¿Dónde se denuncian los casos de hostigamiento o acoso sexual en el trabajo en tu empresa o institución?"
      {...form.getInputProps("SP_004_011_canalesDeDenuncias")}
    >
      <Checkbox label="Jefe/a inmediato" value="sp_004" />
      <Checkbox label="Gerente de área." value="sp_005" />
      <Checkbox label="Representante de trabajadores/as." value="sp_006" />
      <Checkbox label="Plataforma web." value="sp_007" />
      <Checkbox label="Línea ética" value="sp_008" />
      <Checkbox label="Recursos Humanos / Gestión de Talento." value="sp_009" />
      <Checkbox
        label="Comité de hostigamiento sexual / ética / convivencia."
        value="sp_010"
      />
      <Checkbox label="Otro" value="sp_011" />
    </CheckboxSurvey>
  );
};

export default Component;
