import { Radio } from "@mantine/core";
import { RadioSurvey } from "../../components";

type Props = {
  form: any;
};

const Component = ({ form }: Props) => {
  return (
    <RadioSurvey
      label="Esta pregunta se refiere a TODA TU EXPERIENCIA LABORAL incluida tu organización actual como las anteriores ¿Has sido objeto de alguna situación de hostigamiento o acoso sexual laboral?"
      {...form.getInputProps("AD_014")}
    >
      <Radio label="No me ha pasado." value="No me ha pasado." />
      <Radio label="Sí, me ha pasado." value="Sí, me ha pasado." />
      <Radio label="No estoy seguro/a." value="No estoy seguro/a." />
    </RadioSurvey>
  );
};

export default Component;
