import { Checkbox } from "@mantine/core";
import CheckboxSurvey from "../../components/form/Checkbox";

type Props = {
  form: any;
};

const Component = ({ form }: Props) => {
  return (
    <CheckboxSurvey
      label="¿Cuál o cuáles de estas situaciones describirían la situación de hostigamiento o acoso sexual de la que fuiste testigo?"
      {...form.getInputProps("TD_002_013")}
    >
      <Checkbox
        label="Comentarios o bromas de contenido sexual o sexista."
        value="td_002"
      />
      <Checkbox
        label="Comentarios o bromas sobre la orientación sexual o identidad de género de una persona."
        value="td_003"
      />
      <Checkbox
        label="Comentarios sobre la apariencia física o cuerpo de una persona que generan incomodidad, incluyendo también gestos, silbidos o sonidos."
        value="td_004"
      />
      <Checkbox
        label="Invitaciones reiteradas a salir a una persona a pesar de no recibir respuesta o no recibir respuesta positiva."
        value="td_005"
      />
      <Checkbox
        label="Contactos reiterados a través de mensajes electrónicos, chats, WhatsApp, Facebook, entre otros, con fines no laborales que generan incomodidad."
        value="td_006"
      />
      <Checkbox
        label="Solicitud o envío sin consentimiento de fotos o videos de material sexual explícito o de connotación sexual."
        value="td_007"
      />
      <Checkbox
        label="Acercamientos físicos excesivos o tocamientos (rozar, sobar, masajear, etc.) sin necesidad a una persona en alguna parte del cuerpo como hombros, espalda baja, rodilla u otras partes."
        value="td_008"
      />
      <Checkbox
        label="Tocamientos o roces a una persona en zonas privadas del cuerpo como los senos, genitales, etc."
        value="td_009"
      />
      <Checkbox
        label="Miradas persistentes con connotación sexual a una persona."
        value="td_010"
      />
      <Checkbox
        label="Insinuaciones y/o invitaciones de un jefe/a a salir a pesar que de alguna manera se le mostró incomodidad."
        value="td_011"
      />
      <Checkbox
        label="Insinuaciones o propuestas de mejoras en la situación laboral de una persona cambio de aceptar invitaciones, acercamientos o encuentros sexuales."
        value="td_012"
      />
      <Checkbox
        label="Amenazar o insinuar con despedir a una persona, retirarle los beneficios laborales o tomar alguna represalia por no aceptar invitaciones, acercamientos, o encuentros sexuales."
        value="td_013"
      />
    </CheckboxSurvey>
  );
};

export default Component;
