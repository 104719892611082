import {
  Kbd,
  // Container,
  Space,
  Text,
} from "@mantine/core";

export const SurveyAcknowledgement = () => {
  return (
    <>
      <Text ta="justify">Gracias por decidir participar en la encuesta.</Text>
      <Text ta="justify">
        Para facilitar tu navegación por la encuesta, puedes pulsar la tecla{" "}
        <Kbd>Enter</Kbd> para pasar a la siguiente pantalla.
      </Text>
      <Space h="md" />
      <Text>¡Comencemos!</Text>
      <Space h="md" />

      <Text ta="justify">
        En esta primera parte te vamos a hacer algunas preguntas de
        clasificación general y sobre las características de la relación laboral
        que tienes con tu empleador.
      </Text>
    </>
  );
};
