import { Route, Routes } from "react-router-dom";

import { NotFoundPage, SurveyPage } from "./pages";
import { GenderlabHeader } from "./components/GenderlabHeader";
import { GenderlabFooter } from "./components";
import { SurveyFinalThankYou } from "./pages/SurveyPage";

export default function App() {
  return (
    <>
      <GenderlabHeader />
      <Routes>
        <Route path="*" element={<NotFoundPage />} />
        <Route path=":id" element={<SurveyPage previsualizar={false} />} />
        <Route path="preview">
          <Route
            path=":id"
            element={<SurveyPage previsualizar={true} />}
          ></Route>
        </Route>
        <Route path="/gracias" element={<SurveyFinalThankYou />} />
      </Routes>
      <GenderlabFooter />
    </>
  );
}
