import { Space, Text } from "@mantine/core";

const Component = () => {
  return (
    <>
      <Text ta="justify">Gracias por tus respuestas.</Text>
      <Space h="md" />
      <Text ta="justify">
        Vamos a continuar con algunas preguntas relacionadas con las políticas
        de la organización en la que trabajas actualmente.
      </Text>
    </>
  );
};

export default Component;
