import {
  ColorScheme,
  ColorSchemeProvider,
  MantineProvider,
  MantineThemeOverride,
} from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";
import { useCookies } from "react-cookie";

export const theme: MantineThemeOverride = {
  fontFamily:
    "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
  // primaryColor: "red",
  components: {
    Button: {
      styles: {
        root: {
          backgroundColor: "#d92020",
          ":hover": {
            backgroundColor: "#d92020",
          },
        },
      },
    },
  },
};

interface ThemeProviderProps {
  children: React.ReactNode;
}

const PRIMARY_THEME = "light";
const SECONDARY_THEME = "dark";

export function ThemeProvider({ children }: ThemeProviderProps) {
  const [cookies, setCookie] = useCookies(["colorScheme"]);

  const toggleColorScheme = (value?: ColorScheme) => {
    setCookie(
      "colorScheme",
      !cookies.colorScheme
        ? SECONDARY_THEME
        : cookies.colorScheme === PRIMARY_THEME
        ? SECONDARY_THEME
        : PRIMARY_THEME,
      { path: "/" }
    );
  };

  return (
    <ColorSchemeProvider
      colorScheme={cookies.colorScheme || PRIMARY_THEME}
      toggleColorScheme={toggleColorScheme}
    >
      <MantineProvider
        theme={{ ...theme, colorScheme: cookies.colorScheme || PRIMARY_THEME }}
        withGlobalStyles
        withNormalizeCSS
      >
        <NotificationsProvider>{children}</NotificationsProvider>
      </MantineProvider>
    </ColorSchemeProvider>
  );
}
