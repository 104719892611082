import { MultiRadioTable } from "../../components/MultiRadioTable";

type Props = {
  form: any;
};

const Component = ({ form }: Props) => {
  return (
    <MultiRadioTable
      form={form}
      label="En general, ¿qué hiciste cuando sucedió?"
      options={["Sí.", "No."]}
      questions={[
        "Se lo conté a un compañero/a.",
        "Hablé con mi jefe/a directo/a.",
        "Evité y me alejé de la persona.",
        "Pedí que me cambien de lugar de trabajo con alguna otra excusa.",
        "Confronté a la persona.",
        "No comenté lo sucedido con nadie de mi organización.",
        "Hablé con Recursos Humanos pero decidí no seguir el proceso de investigación.",
        "Lo denuncié y seguí el proceso de investigación.",
      ]}
      questionIds={[
        "AAD_001",
        "AAD_002",
        "AAD_003",
        "AAD_004",
        "AAD_005",
        "AAD_006",
        "AAD_007",
        "AAD_008",
      ]}
    />
  );
};

export default Component;
