import { Checkbox } from "@mantine/core";
import CheckboxSurvey from "../../components/form/Checkbox";

type Props = {
  form: any;
};

const Component = ({ form }: Props) => {
  return (
    <CheckboxSurvey
      label="¿Cuál es el género de la(s) personas(s) que realizó o realizaron esta(s) conducta(s)? (Si fue más de una persona, refiere aquella cuyos actos hayan sido más serios para ti)"
      {...form.getInputProps("PAD_001_002")}
    >
      <Checkbox label="Hombre." value="pad_001" />
      <Checkbox label="Mujer." value="pad_002" />
    </CheckboxSurvey>
  );
};

export default Component;
