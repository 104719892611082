import { Radio } from "@mantine/core";
import { RadioSurvey } from "../../components";

type Props = {
  form: any;
};

const Component = ({ form }: Props) => {
  return (
    <RadioSurvey
      label="¿Conoces si en tu empresa o institución existe un canal de denuncia para reportar hostigamiento o acoso sexual en el trabajo?"
      {...form.getInputProps("SP_003_canalDeDenunciasExiste")}
    >
      <Radio
        label="Sí, y sé cómo acceder a él."
        value="Sí, y sé cómo acceder a él."
      />
      <Radio
        label="Sé que existe pero no sé cómo acceder a él."
        value="Sé que existe pero no sé cómo acceder a él."
      />
      <Radio label="No." value="No." />
    </RadioSurvey>
  );
};

export default Component;
