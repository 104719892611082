import { Container, Space, Text} from "@mantine/core";

export const SurveyWelcome2 = () => {
  return (
    <Container fluid>
      <Text fw={700}>Garantía de confidencialidad</Text>
      <ul>
        <li>
          <Text ta="justify">
            ELSA es una herramienta digital administrada por GenderLab. La
            información recogida es anónima y confidencial. Tu organización solo
            tendrá acceso a los resultados globales, mas no a tus respuestas a
            la encuesta.
          </Text>
        </li>
      </ul>
      <Space h="md" />
      <Text fw={700}>Duración de la encuesta</Text>
      <ul>
        <li>
          <Text ta="justify">
            La encuesta tiene una duración variable ya que depende de las
            respuestas que vayas dando. Estimamos que en ningún caso su
            extensión será superior a los 15 minutos. ¡Muchas gracias por tu
            apoyo!
          </Text>
        </li>
      </ul>
    </Container>
  );
};
