import { MultiRadioTable } from "../../components/MultiRadioTable";

type Props = {
  form: any;
};

const Component = ({ form }: Props) => {
  return (
    <MultiRadioTable
      form={form}
      label="¿Qué tan de acuerdo estás con las siguientes afirmaciones?"
      options={[
        "Totalmente de acuerdo.",
        "De acuerdo.",
        "En desacuerdo.",
        "Totalmente en desacuerdo.",
      ]}
      questions={[
        "Si denuncio un caso de hostigamiento o acoso sexual laboral, me podría generar consecuencias en mi trabajo.",
        "Un caso de hostigamiento o acoso sexual en el que se acusa a una persona que no pertenece a la plana gerencial no sería investigado adecuadamente en mi trabajo.",
        "Un caso de hostigamiento o acoso sexual en el que se acusa a una persona que pertenece a la plana gerencial no sería investigado adecuadamente en mi trabajo.",
        "El hostigamiento o acoso sexual no es tolerado en mi ambiente laboral.",
        "Las personas que hostigan o acosan a otras/os normalmente se salen con la suya (por ejemplo, no son sancionadas).",
        "Se están tomando acciones efectivas contra el hostigamiento o acoso sexual en mi trabajo.",
      ]}
      questionIds={[
        "CON_001",
        "CON_002",
        "CON_003",
        "CON_004",
        "CON_005",
        "CON_006",
      ]}
    />
  );
};

export default Component;
