import { Checkbox } from "@mantine/core";
import CheckboxSurvey from "../../components/form/Checkbox";

type Props = {
  form: any;
};

const Component = ({ form }: Props) => {
  return (
    <CheckboxSurvey
      label="En general, ¿qué hiciste cuando sucedió?"
      {...form.getInputProps("ACT_001_008")}
    >
      <Checkbox
        label="Generé una distracción (por ejemplo, cambié el tema de conversación)."
        value="act_001"
      />
      <Checkbox
        label="Confronté a la persona que estaba haciendo estas conductas."
        value="act_002"
      />
      <Checkbox
        label="Informé de lo sucedido a recursos humanos o a mi jefe/a."
        value="act_003"
      />
      <Checkbox
        label="En ese momento no intervine pero luego me acerqué a la persona para ver cómo estaba."
        value="act_004"
      />
      <Checkbox
        label="En ese momento no intervine pero luego me acerqué a quien incurrió en acoso u hostigamiento."
        value="act_005"
      />
      <Checkbox
        label="Solo guardé un registro de lo sucedido (por ejemplo, guardé los chats)."
        value="act_006"
      />
      <Checkbox
        label="No intervine porque no supe qué hacer en ese momento."
        value="act_007"
      />
      <Checkbox label="No me pareció necesario intervenir." value="act_008" />
    </CheckboxSurvey>
  );
};

export default Component;
