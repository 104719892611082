// LogoContext.tsx

import React, { createContext, useState, ReactNode, useContext } from "react";

type LogoContextType = {
  logo: string;
  updateLogo: (newLogo: string) => void;
};

const LogoContext = createContext<LogoContextType>(null!);

type LogoProviderProps = {
  children: ReactNode;
};

const LogoProvider = ({ children }: LogoProviderProps) => {
  const [logo, setLogo] = useState("");

  const updateLogo = (newLogo: string) => {
    setLogo(newLogo);
  };

  return (
    <LogoContext.Provider value={{ logo, updateLogo }}>
      {children}
    </LogoContext.Provider>
  );
};

const useLogo = () => useContext(LogoContext);

export { LogoContext, LogoProvider };
export { useLogo };
