import { MultiRadioTable } from "../../components/MultiRadioTable";

type Props = {
  form: any;
};

const Component = ({ form }: Props) => {
  return (
    <MultiRadioTable
      form={form}
      label="A raíz de estas situaciones, ¿cómo te sentiste? "
      options={["Sí.", "No."]}
      questions={[
        "Me sentí ansioso/a.",
        "Me sentí estresado/a.",
        "Tuve dificultades para concentrarme.",
        "Reduje mi productividad.",
        "Comencé a ausentarme del trabajo.",
      ]}
      questionIds={["COS_001", "COS_002", "COS_003", "COS_004", "COS_005"]}
    />
  );
};

export default Component;
