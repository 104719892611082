import { Text } from "@mantine/core";

type Props = {
  children: string;
};

export const ErrorText = ({ children }: Props) => {
  return (
    <Text size={12} color="#fa5252" mt={10}>
      {children}
    </Text>
  );
};
